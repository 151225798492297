import { SET_ROLE_DATA, EDIT_ROLE_FLAG, EDIT_ROLE_RECORD } from '../../../constants/ActionTypes';

const INIT_STATE = {
    rolesRecord: [],
    editFlag:false,
    editRecord:null,
};


const AddRole = (state = INIT_STATE, action) => {
    switch (action.type) {
      case SET_ROLE_DATA: {
        return {
          ...state,
          rolesRecord: action.payload,
        }
      };
      case EDIT_ROLE_FLAG: {
        return {
          ...state,
          editFlag: action.payload,
        }
      };
      case EDIT_ROLE_RECORD: {
        return {
          ...state,
          editRecord: action.payload,
        }
      };
      default:
        return state;
    }
  }
  
export default AddRole;
  