import {
  ADDED_FAV_PROCEDURE,
  REMOVE_FAV_PROCEDURE,
} from "../../constants/ActionTypes";

export function AddedFavProcedure(values) {
  return (dispatch) => dispatch({ type: ADDED_FAV_PROCEDURE, payload: values });
}

export function RemoveFavProcedure(value) {
  return (dispatch) => dispatch({ type: REMOVE_FAV_PROCEDURE, payload: value });
}
