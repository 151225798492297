import { MENU } from "../static-data/sidebar-menu";
import { ROUTES } from "../static-data/Routes";

const baseURL = "https://hmisapidev.ssasoft.com/HMS/";

export function debounce(func, delay) {
  let debounceTimer;
  return function () {
    const context = this;
    const args = arguments;
    clearTimeout(debounceTimer);
    debounceTimer = setTimeout(() => func.apply(context, args), delay);
  };
}

export function constructImageURL(filePath, fileName) {
  const uploadIndex = filePath.indexOf("Upload");
  const relevantFilePath = filePath.substring(uploadIndex);
  const modifiedFilePath = relevantFilePath.replace(/\\/g, "/");
  return `${baseURL}${modifiedFilePath}/${fileName}`;
}

export function viewImage(url, fileName) {
  const link = document.createElement("a");
  link.href = url;
  link.target = "_blank";
  link.setAttribute("download", fileName);

  document.body.appendChild(link);

  link.click();

  document.body.removeChild(link);
}

export function restrictPatientRoutes() {
  const authUser = JSON.parse(localStorage.getItem("authUser"));
  const menuInfo = authUser?.User?.MenuInfo;
  return menuInfo
    .filter((menu) => menu.Mname === "Patient Management")
    .map((item) => item.PageURL);
}

export function toCamelCase(str) {
  if (!str) return "";
  return str
    .toLowerCase()
    .split(" ")
    .map((word, index) =>
      index === 0 ? word : word.charAt(0).toUpperCase() + word.slice(1)
    )
    .join("");
}

export function importComponentURL(pageURL) {
  return ROUTES[pageURL];
}

export function getMenuIcon(menuIcon) {
  return MENU[menuIcon];
}

export const convertToMenuStructure = (data) => {
  const menuData = [];

  // Group pages by MenuID
  data.forEach((item) => {
    const existingMenuIndex = menuData.findIndex(
      (rec) => rec.MenuID === item.MenuID
    );
    if (existingMenuIndex === -1 && !item.ParentMenuId) {
      menuData.push({
        MenuID: item.MenuID,
        Mname: item.Mname,
        MPriority: item.MPriority,
        ParentMenuId: item.ParentMenuId,
        MIcon: item.MIcon,
        Pages: [
          {
            MenuID: item.MenuID,
            PageId: item.PageId,
            PName: item.PName,
            PageURL: item.PageURL,
            PPriority: item.PPriority,
            ReadState: item.ReadState,
            InsertState: item.InsertState,
            UpdateState: item.UpdateState,
            DeleteState: item.DeleteState,
            ApproveState: item.ApproveState,
            CloseState: item.CloseState,
          },
        ],
      });
    } else if (existingMenuIndex !== -1 && item.ParentMenuId) {
      const menuIndex = menuData.findIndex(
        (rec) => rec.MenuID === item.ParentMenuId
      );
      const findPageIndex = menuData[menuIndex].Pages.findIndex(
        (rec) => rec.MenuID === item.ParentMenuId
      );
      if (findPageIndex !== -1) {
        menuData[menuIndex].Pages[findPageIndex].Pages = [];
        menuData[menuIndex].Pages[findPageIndex].Pages.push({
          MenuID: item.MenuID,
          Mname: item.Mname,
          MPriority: item.MPriority,
          ParentMenuId: item.ParentMenuId,
          MIcon: item.MIcon,
          Pages: [
            {
              MenuID: item.MenuID,
              PageId: item.PageId,
              PName: item.PName,
              PageURL: item.PageURL,
              PPriority: item.PPriority,
              ReadState: item.ReadState,
              InsertState: item.InsertState,
              UpdateState: item.UpdateState,
              DeleteState: item.DeleteState,
              ApproveState: item.ApproveState,
              CloseState: item.CloseState,
            },
          ],
        });
      }
    } else if (existingMenuIndex === -1 && item.ParentMenuId) {
      const menuIndex = menuData.findIndex(
        (rec) => rec.MenuID === item.ParentMenuId
      );
      if (menuIndex === -1) {
        menuData.push({
          MenuID: item.ParentMenuId,
          Mname: item.ParentMenuName,
          MPriority: item.ParentMenuPriority,
          ParentMenuId: 0,
          MIcon: item.ParentMenuIcon,
          Pages: [
            {
              MenuID: item.MenuID,
              Mname: item.Mname,
              MPriority: item.MPriority,
              ParentMenuId: item.ParentMenuID,
              MIcon: item.MIcon,
              Pages: [
                {
                  MenuID: item.MenuID,
                  PageId: item.PageId,
                  PName: item.PName,
                  PageURL: item.PageURL,
                  PPriority: item.PPriority,
                  ReadState: item.ReadState,
                  InsertState: item.InsertState,
                  UpdateState: item.UpdateState,
                  DeleteState: item.DeleteState,
                  ApproveState: item.ApproveState,
                  CloseState: item.CloseState,
                },
              ],
            },
          ],
        });
      } else {
        const subMenuIndex = menuData[menuIndex].Pages.findIndex(
          (rec) => rec.MenuID === item.MenuID
        );
        if (subMenuIndex !== -1) {
          menuData[menuIndex].Pages[subMenuIndex].Pages.push({
            MenuID: item.MenuID,
            PageId: item.PageId,
            PName: item.PName,
            PageURL: item.PageURL,
            PPriority: item.PPriority,
            ReadState: item.ReadState,
            InsertState: item.InsertState,
            UpdateState: item.UpdateState,
            DeleteState: item.DeleteState,
            ApproveState: item.ApproveState,
            CloseState: item.CloseState,
          });
        } else {
          menuData[menuIndex].Pages.push({
            MenuID: item.MenuID,
            Mname: item.Mname,
            MPriority: item.MPriority,
            ParentMenuId: item.ParentMenuId,
            MIcon: item.MIcon,
            Pages: [
              {
                MenuID: item.MenuID,
                PageId: item.PageId,
                PName: item.PName,
                PageURL: item.PageURL,
                PPriority: item.PPriority,
                ReadState: item.ReadState,
                InsertState: item.InsertState,
                UpdateState: item.UpdateState,
                DeleteState: item.DeleteState,
                ApproveState: item.ApproveState,
                CloseState: item.CloseState,
              },
            ],
          });
        }
      }
    } else {
      menuData[existingMenuIndex].Pages.push({
        MenuID: item.MenuID,
        PageId: item.PageId,
        PName: item.PName,
        PageURL: item.PageURL,
        PPriority: item.PPriority,
        ReadState: item.ReadState,
        InsertState: item.InsertState,
        UpdateState: item.UpdateState,
        DeleteState: item.DeleteState,
        ApproveState: item.ApproveState,
        CloseState: item.CloseState,
      });
    }
  });

  return menuData;
};
