import { useAuth } from "../../../authentication";
import { postApi, putApi, getApi } from "../../Axios";

export const useEmployee = () => {
  const { getLogout } = useAuth();

  const getEmployee = (callbackFun) => {
    getApi(`EmployeeReg`, callbackFun, "data", getLogout, false);
  };

  const addEmployee = (employee, callbackFun) => {
    postApi(`EmployeeReg`, employee, callbackFun, "msg", getLogout, true);
  };

  const updateEmployee = (employee, callbackFun) => {
    putApi(`EmployeeReg`, employee, callbackFun, "msg", getLogout, true);
  };

  return {
    addEmployee,
    getEmployee,
    updateEmployee,
  };
};
