import { SET_ASSIGNED_ROLE_TO_USER_DATA, EDIT_ASSIGNED_ROLE_TO_USER_FLAG, EDIT_ASSIGNED_ROLE_TO_USER_RECORD } from '../../../constants/ActionTypes';

export function setAssignedRoleToUserData(values) {
    return (dispatch) => {
      dispatch({
        type: SET_ASSIGNED_ROLE_TO_USER_DATA,
        payload: values
      });
    }
}

export function EditAssignedRoleToUserFlag(values) {
    return (dispatch) => {
      dispatch({
        type: EDIT_ASSIGNED_ROLE_TO_USER_FLAG,
        payload: values
      });
    }
}

export function EditAssignedRoleToUserRecord(values) {
    return (dispatch) => {
      dispatch({
        type: EDIT_ASSIGNED_ROLE_TO_USER_RECORD,
        payload: values
      });
    }
}