import {
  ADDED_FAV_INVEST,
  REMOVE_FAV_INVEST,
} from "../../constants/ActionTypes";
import _ from "lodash";

const INIT_STATE = {
  addedFavInvest: [],
  isDataLoaded: false,
};

const FavInvestigation = (state = INIT_STATE, action) => {
  switch (action.type) {
    case ADDED_FAV_INVEST: {
      return {
        ...state,
        addedFavInvest: action.payload,
        isDataLoaded: true,
      };
    }
    case REMOVE_FAV_INVEST: {
      var { favInvestigation } = state;
      var filteredResults;
      filteredResults = favInvestigation.filter(
        (record) => record.favouritID !== action.payload
      );

      return {
        ...state,
        addedFavInvest: filteredResults,
      };
    }
    default:
      return state;
  }
};

export default FavInvestigation;
