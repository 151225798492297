import { SET_SERVICE_MAIN_GROUP_DATA } from '../../../constants/ActionTypes';

const INIT_STATE = {
    serviceMainGroup : [],
};

const AddServiceMainGroup = (state = INIT_STATE, action) => {
    switch (action.type) {
      case SET_SERVICE_MAIN_GROUP_DATA: {
        return {
          ...state,
          serviceMainGroup: action.payload,
        }
      };
      default:
        return state;
    }
  }
  
  export default AddServiceMainGroup;
  