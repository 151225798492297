import { SET_ROLE_DATA, EDIT_ROLE_FLAG, EDIT_ROLE_RECORD } from '../../../constants/ActionTypes';

export function setRoleData(values) {
    return (dispatch) => {
      dispatch({
        type: SET_ROLE_DATA,
        payload: values
      });
    }
}

export function EditRoleFlag(values) {
    return (dispatch) => {
      dispatch({
        type: EDIT_ROLE_FLAG,
        payload: values
      });
    }
}

export function EditRoleRecord(values) {
    return (dispatch) => {
      dispatch({
        type: EDIT_ROLE_RECORD,
        payload: values
      });
    }
}