import {SET_PATIENT_CATEGORY_DATA, EDIT_SERVICE_RATE_FLAG, EDIT_SERVICE_RATE_RECORD} from '../../../constants/ActionTypes';

const INIT_STATE = {
    patientCategoriesData : [],
    editFlag:false,
    editRecord:null,
};

const DiscountPolicy = (state = INIT_STATE, action) => {
    switch (action.type) {

      case SET_PATIENT_CATEGORY_DATA: {
        return {
          ...state,
          patientCategoriesData: action.payload,
        }
      };

    //   case EDIT_SERVICE_FLAG: {
    //     return {
    //       ...state,
    //       editFlag: action.payload,
    //     }
    //   };

    //   case EDIT_SERVICE_RECORD: {
    //     return {
    //       ...state,
    //       editRecord: action.payload,
    //     }
    //   };

      default:
        return state;
    }
  }
  
  export default DiscountPolicy;
  