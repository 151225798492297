import {
  ADD_DIAG_SESSION_DATA,
  CLEAR_DIAG_SESSION_DATA,
  REMOVE_DIAG_FROM_SESSION,
  ADDED_VISIT_PATIENT_DIAGS,
} from "../../constants/ActionTypes";

export function AddDiagSessionData(values) {
  return (dispatch) =>
    dispatch({ type: ADD_DIAG_SESSION_DATA, payload: values });
}

export function ClearDiagSessionData() {
  return (dispatch) => dispatch({ type: CLEAR_DIAG_SESSION_DATA });
}

export function RemoveDiagFromSession(value) {
  return (dispatch) =>
    dispatch({ type: REMOVE_DIAG_FROM_SESSION, payload: value });
}

export function AddedVisitPtntDiags(value) {
  return (dispatch) =>
    dispatch({ type: ADDED_VISIT_PATIENT_DIAGS, payload: value });
}
