import {
  SET_CREATE_LOGIN_DATA,
  EDIT_CREATE_LOGIN_FLAG,
  EDIT_CREATE_LOGIN_RECORD,
} from "../../../constants/ActionTypes";

const INIT_STATE = {
  createdUserLogin: {},
  editFlag: false,
  editRecord: null,
};

const CreateLogin = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_CREATE_LOGIN_DATA: {
      // let extendedPayload = [];
      // action.payload.map((item, index) => {
      //   let newItem = {
      //     ...item,
      //     isBranchAcces: item.BranchAccess ? "Yes" : "No",
      //   };
      //   extendedPayload.push(newItem);
      // });
      return {
        ...state,
        createdUserLogin: action.payload,
      };
    }

    case EDIT_CREATE_LOGIN_FLAG: {
      return {
        ...state,
        editFlag: action.payload,
      };
    }

    case EDIT_CREATE_LOGIN_RECORD: {
      return {
        ...state,
        editRecord: action.payload,
      };
    }
    default:
      return state;
  }
};

export default CreateLogin;
