import {
    SET_PATIENT_DEMOGRAPHIC_DATA,     
    SET_PATIENT_DIAGNOSIS_HISTORY,  
    SET_PATIENT_INVESTIGATION_LAB_HISTORY,
    SET_PATIENT_INVESTIGATION_RAD_HISTORY,
    SET_PATIENT_MEDICATION_HISTORY, 
    SET_PATIENT_ERM_HISTORY, 
    SET_PATIENT_PROCEDURE_HISTORY,
    SET_PATIENT_OUTCOME_HISTORY, 
    SET_PATIENT_ADMISSION_HISTORY,
    SET_PATIENT_SEARCH_HISTORY,
      } from "../../constants/ActionTypes";
import ChangeDateFormatFromAPIToLocal from "../../util/ChangeDateFormatFromAPIToLocal";
      

      const INIT_STATE = {
        setPatientInfoBarData: {
          demographicData :{},
          vitalsData : []
        },
        setPatientDiagnosisHistory:  {
          PDH : [],
          PILH : [],
          PIRH : [],
          PMH : [],
          PEH : [],
        },
        setPatientInvestigationLabHistory: [],
        setPatientInvestigationRadHistory: [],
        setPatientMedicationHistory: [],
        setPatientERMHistory: [],
        setPatientProcedureHistory: [],
        setPatientOutcomeHistory: [],
        setPatientAdmissionHistory: [],
        setPatientSearchedHistory: [],
        dataLoaded: false,

      };
      
      const PatientHistory = (state = INIT_STATE, action) => {
        switch (action.type) {
          case SET_PATIENT_DEMOGRAPHIC_DATA: {
            return {
              ...state,
              setPatientInfoBarData: action.payload,
              dataLoaded: true,
            };
          }
          case SET_PATIENT_DIAGNOSIS_HISTORY: {
            return {
              ...state,
              setPatientDiagnosisHistory: action.payload,
            };
          }
          case SET_PATIENT_INVESTIGATION_LAB_HISTORY: {
            return {
              ...state,
              setPatientInvestigationLabHistory: action.payload,
            };
          }
          case SET_PATIENT_INVESTIGATION_RAD_HISTORY: {
            return {
              ...state,
              setPatientInvestigationRadHistory: action.payload,
            };
          }
          case SET_PATIENT_MEDICATION_HISTORY: {
            return {
              ...state,
              setPatientMedicationHistory: action.payload,
            };
          }
          case SET_PATIENT_ERM_HISTORY: {
            return {
              ...state,
              setPatientERMHistory: action.payload,
            };
          }
          case SET_PATIENT_PROCEDURE_HISTORY: {
            return {
              ...state,
              setPatientProcedureHistory: action.payload,
            };
          }
          case SET_PATIENT_OUTCOME_HISTORY: {
            return {
              ...state,
              setPatientOutcomeHistory: action.payload,
            };
          }
          case SET_PATIENT_ADMISSION_HISTORY: {
            return {
              ...state,
              setPatientAdmissionHistory: action.payload,
            };
          }
          case SET_PATIENT_SEARCH_HISTORY: {
            return {
              ...state,
              setPatientSearchedHistory: action.payload,
            };
          }
          default:
            return state;
        }
      }

export default PatientHistory;
