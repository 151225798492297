import {
    SET_SHIFTS,EDIT_SHIFTS,
  } from "../../constants/ActionTypes";
  


  export function SetShiftsData(values) {
    return (dispatch) => dispatch({ type: SET_SHIFTS, payload: values });
  }
  
  export function EditShiftsData(values) {
    return (dispatch) => dispatch({ type: EDIT_SHIFTS, payload: values });
  }