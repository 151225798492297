import { useState } from "react";
import { message } from "antd";
import { getApi } from "../Axios";
import { httpClient } from "../Api";
import { getApi as newGetApi } from "../Axios_Dev";

export const useHospitals = () => {
  const [error, setError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [isLoading, setLoading] = useState(false);

  const addHospital = (hospital, callbackFun) => {
    message.loading("Please Wait...");
    httpClient
      .post(`Administration/Hospital`, hospital)
      .then(({ data }) => {
        message.destroy();
        message.success(data);
        if (callbackFun)
          getHospitalGroupById(hospital.mainGroupID, callbackFun);
      })
      .catch(function (error) {
        message.error(error.message);
      });
  };

  const getHospitalType = (LookupType, PType, callbackFun) => {
    newGetApi(
      `Pharmacy/LookUps/GetHsptlLookup/${LookupType},${PType}`,
      callbackFun,
      "data",
      false
    );
  };

  const getHospitals = (callbackFun) => {
    httpClient
      .get(`Administration/Hospital`)
      .then(({ data }) => {
        if (data != "No Record Found" && callbackFun) callbackFun(data);
      })
      .catch(function (error) {
        message.error(error.message);
      });
  };

  const getHospitalById = (mainGroupId, hospitalId, callbackFun) => {
    httpClient
      .get(`Administration/Hospital/${mainGroupId},${hospitalId}`)
      .then(({ data }) => {
        if (callbackFun) callbackFun(data);
      })
      .catch(function (error) {
        message.error(error.message);
      });
  };

  const getHospitalGroupById = (mainGroupId, callbackFun) => {
    httpClient
      .get(`Administration/Hospital/${mainGroupId}`)
      .then(({ data }) => {
        if (data == "No Record Found") callbackFun([]);
        else callbackFun(data);
      })
      .catch(function (error) {
        message.error(error.message);
      });
  };

  const updateHospital = (hospital, callbackFun) => {
    message.loading("Please Wait...");
    httpClient
      .put(`Administration/Hospital`, hospital)
      .then(({ data }) => {
        message.destroy();
        message.success("Updated successfully");
        if (callbackFun)
          getHospitalGroupById(hospital.mainGroupID, (data) => {
            callbackFun(data);
          });
      })
      .catch(function (error) {
        message.error(error.message);
      });
  };

  return {
    addHospital,
    getHospitalType,
    getHospitals,
    getHospitalById,
    getHospitalGroupById,
    updateHospital,
    isLoading,
    error,
    successMessage,
  };
};
