import {
  ADDED_FAV_MEDICATION,
  REMOVE_FAV_MEDICATION,
} from "../../constants/ActionTypes";

const INIT_STATE = {
  addedFavMed: [],
  isDataLoaded: false,
};

const FavMedication = (state = INIT_STATE, action) => {
  switch (action.type) {
    case ADDED_FAV_MEDICATION: {
      return {
        ...state,
        addedFavMed: action.payload,
        isDataLoaded: true,
      };
    }
    case REMOVE_FAV_MEDICATION: {
      var { favMedication } = state;
      var filteredResults;
      filteredResults = favMedication.filter(
        (record) => record.sr !== action.payload
      );

      return {
        ...state,
        addedFavMed: filteredResults,
      };
    }
    default:
      return state;
  }
};

export default FavMedication;
