import { useState } from "react";
import { httpClient } from "../Api";
import {
  fetchStart as gFetchStart,
  fetchSuccess as gFetchSuccess,
  fetchError as gFetchError,
  showMessage as gShowMessage,
  hideMessage as gHideMessage,
} from "../../appRedux/actions";
import { useDispatch } from "react-redux";

export const useCommon = () => {
  const [error, setError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [isLoading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const fetchStart = () => {
    dispatch(gFetchStart());
  };

  const fetchSuccess = (message) => {
    dispatch(gFetchSuccess());
    dispatch(gShowMessage(message));
  };

  const fetchError = (error) => {
    dispatch(gFetchError(error));
  };

  const getCountries = (callbackFun) => {
    fetchStart();
    httpClient
      .get(`DefaultMethods/Getcountry`)
      .then(({ data }) => {
        fetchSuccess("");
        if (callbackFun) callbackFun(data);
      })
      .catch(function (error) {
        fetchError(error.message);
      });
  };

  

  return {
    getCountries,
    isLoading,
    error,
    successMessage,
  };
};
