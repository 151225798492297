import { httpClient } from "../../Api";
import {
  fetchStart as gFetchStart,
  fetchSuccess as gFetchSuccess,
  fetchError as gFetchError,
  showMessage as gShowMessage,
  hideMessage as gHideMessage,
} from "../../../appRedux/actions";
import { useDispatch } from "react-redux";

export const useServiceRateSetup = () => {
  const dispatch = useDispatch();

  const fetchStart = () => {
    dispatch(gFetchStart());
  };

  const fetchSuccess = (message) => {
    dispatch(gFetchSuccess());
    dispatch(gShowMessage(message));
  };

  const fetchError = (error) => {
    dispatch(gFetchError(error));
  };

  const addServiceRate = (model, callbackFun, filterServiceRate, type) => {
    const { serviceID } = model;
    fetchStart();
    httpClient
      .post(`Administration/ServicesRate/Post`, model)
      .then(({ data }) => {
        fetchSuccess("Added Successfully!");
        if (callbackFun)
          getServicesRateList(serviceID, callbackFun, filterServiceRate, type);
      })
      .catch(function (error) {
        fetchError(error.message);
      });
  };

  const getServicesRateList = (
    serviceId,
    callbackFun,
    filterServiceRate,
    type
  ) => {
    fetchStart();
    httpClient
      .get(`Administration/ServicesRate/GetServicesRateList/${serviceId}`)
      .then(({ data }) => {
        fetchSuccess("");
        if (data != "No Record Found") {
          if (callbackFun) callbackFun(data);
          filterServiceRate(type, data);
        }
        if (data == "No Record Found") if (callbackFun) callbackFun(null);
      })
      .catch(function (error) {
        fetchError(error.message);
      });
  };

  const getServicesLateralityList = (callbackFun) => {
    fetchStart();
    httpClient
      .get(`Administration/ServicesRate/GetServicesLaterality`)
      .then(({ data }) => {
        fetchSuccess("");
        if (data != "No Record Found") {
          if (callbackFun) callbackFun(data);
        }
        if (data == "No Record Found") if (callbackFun) callbackFun(null);
      })
      .catch(function (error) {
        fetchError(error.message);
      });
  };

  const getServicesRateType = (callbackFun) => {
    fetchStart();
    httpClient
      .get(`Administration/ServicesRate/GetServicesType`)
      .then(({ data }) => {
        fetchSuccess("");
        if (data != "No Record Found") {
          if (callbackFun) callbackFun(data);
        }
        if (data == "No Record Found") if (callbackFun) callbackFun(null);
      })
      .catch(function (error) {
        fetchError(error.message);
      });
  };

  const getDoctorsByServiceId = (serviceId, callbackFun) => {
    // fetchStart();
    httpClient
      .get(`Administration/ServicesRate/GetDoctor?ServiceID=${serviceId}`)
      .then(({ data }) => {
        // fetchSuccess("");
        // if (data != "No Record Found") {
          if (callbackFun) callbackFun(data);
        // }
        // if (data == "No Record Found") if (callbackFun) callbackFun(null);
      })
      .catch(function (error) {
        // fetchError(error.message);
        console.log(error.message)
      });
  };

  const updateServiceRate = (
    serviceRateId,
    service,
    callbackFun,
    filterServiceRate,
    type
  ) => {
    fetchStart();
    httpClient
      .put(`Administration/ServicesRate/Put/${serviceRateId}`, service)
      .then(({ data }) => {
        fetchSuccess("Updated Successfully!");
        if (callbackFun)
          getServicesRateList(
            service.serviceID,
            callbackFun,
            filterServiceRate,
            type
          );
      })
      .catch(function (error) {
        fetchError(error.message);
      });
  };

  return {
    addServiceRate,
    getServicesRateList,
    getServicesLateralityList,
    getServicesRateType,
    updateServiceRate,
    getDoctorsByServiceId
  };
};
