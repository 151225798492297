import { SET_SINGLE_USER_ROLE_DATA, EDIT_CREATE_LOGIN_FLAG, EDIT_CREATE_LOGIN_RECORD, SET_SINGLE_USER_ROLE_MENU_PAGE_DATA } from '../../../constants/ActionTypes';

const INIT_STATE = {
    singleUserRoleData : [],
    singleUserRoleMenuPageData:[],
    editFlag: false,
    editRecord: null,
};

const RollingBackRole = (state = INIT_STATE, action) => {
    switch (action.type) {
      case SET_SINGLE_USER_ROLE_DATA: {
        return {
          ...state,
          singleUserRoleData: action.payload,
        }
      };

      case SET_SINGLE_USER_ROLE_MENU_PAGE_DATA: {
        return {
          ...state,
          singleUserRoleMenuPageData: action.payload,
        }
      };

    //   case EDIT_CREATE_LOGIN_FLAG: {
    //     return {
    //       ...state,
    //       editFlag: action.payload,
    //     }
    //   };

    //   case EDIT_CREATE_LOGIN_RECORD: {
    //     return {
    //       ...state,
    //       editRecord: action.payload,
    //     }
    //   };
      default:
        return state;
    }
  }
  
  export default RollingBackRole;
  