import { SET_ASSIGNED_MODULES_TO_ROLES_DATA, EDIT_ASSIGNED_MODULES_TO_ROLES_FLAG, EDIT_ASSIGNED_MODULES_TO_ROLES_RECORD, LOAD_ASSIGNED_MODULES_TO_ROLES_RECORD, LOAD_ASSIGNED_MODULES_TO_ROLES_FLAG } from '../../../constants/ActionTypes';

export function setAssignedModulesToRoleData(values) {
    return (dispatch) => {
      dispatch({
        type: SET_ASSIGNED_MODULES_TO_ROLES_DATA,
        payload: values
      });
    }
}

export function EditAssignedModulesToRoleFlag(values) {
    return (dispatch) => {
      dispatch({
        type: EDIT_ASSIGNED_MODULES_TO_ROLES_FLAG,
        payload: values
      });
    }
}

export function EditAssignedModulesToRoleRecord(values) {
    return (dispatch) => {
      dispatch({
        type: EDIT_ASSIGNED_MODULES_TO_ROLES_RECORD,
        payload: values
      });
    }
}

export function loadAssignedModulesToRoleRecord(values) {
  return (dispatch) => {
    dispatch({
      type: LOAD_ASSIGNED_MODULES_TO_ROLES_RECORD,
      payload: values
    });
  }
}

export function loadAssignedModulesToRoleFlag(values) {
  return (dispatch) => {
    dispatch({
      type: LOAD_ASSIGNED_MODULES_TO_ROLES_FLAG,
      payload: values
    });
  }
}