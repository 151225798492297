import {
  SET_HOSPITALS_RECORD,
  EDIT_HOSPITAL_FLAG,
  EDIT_HOSPITAL_RECORD,
  SAVE_EDITED_HOSPITAL_RECORD,
  SET_COUNTRY_VALUE,
  SET_MAIN_GROUP_ID,
} from "../../../constants/ActionTypes";

export function setHospitalsData(values) {
  return (dispatch) => {
    dispatch({
      type: SET_HOSPITALS_RECORD,
      payload: values,
    });
  };
}

export function EditHospitalFlag(values) {
  return (dispatch) => {
    dispatch({
      type: EDIT_HOSPITAL_FLAG,
      payload: values,
    });
  };
}

export function EditHospitalRecord(values) {
  return (dispatch) => {
    dispatch({
      type: EDIT_HOSPITAL_RECORD,
      payload: values,
    });
  };
}

export function SaveEditedHospitalRecord(values) {
  return (dispatch) => {
    dispatch({
      type: SAVE_EDITED_HOSPITAL_RECORD,
      payload: values,
    });
  };
}

export function setCountryValue(value) {
  return (dispatch) => {
    dispatch({
      type: SET_COUNTRY_VALUE,
      payload: value,
    });
  };
}

export function setMainGroupId(values) {
  return (dispatch) => {
    dispatch({
      type: SET_MAIN_GROUP_ID,
      payload: values,
    });
  };
}
