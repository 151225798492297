import { SET_DEPARTMENT_DATA, EDIT_DEPARTMENT_FLAG, EDIT_DEPARTMENT_RECORD, SAVE_EDITED_DEPARTMENT_RECORD } from '../../../constants/ActionTypes';

export function setDepartmentsData(values) {
    return (dispatch) => {
      dispatch({
        type: SET_DEPARTMENT_DATA,
        payload: values
      });
    }
}

export function EditDepartmentFlag(values) {
    return (dispatch) => {
      dispatch({
        type: EDIT_DEPARTMENT_FLAG,
        payload: values
      });
    }
}

export function EditDepartmentRecord(values) {
    return (dispatch) => {
      dispatch({
        type: EDIT_DEPARTMENT_RECORD,
        payload: values
      });
    }
}

export function SaveEditedDepartmentRecord(values) {
  return (dispatch) => {
    dispatch({
      type: SAVE_EDITED_DEPARTMENT_RECORD,
      payload: values
    });
  }
}