import {
  SET_PACKAGE_DATA,
  EDIT_PACKAGE_FLAG,
  EDIT_PACKAGE_RECORD,
  SET_PACKAGE_TYPE_DATA,
  SET_SERVICES_GROUP_WISE_NOT_ASSIGNED_DATA,
  SET_DISCOUNT_IN,
  SET_DISCOUNT_PERCENTAGE,
} from "../../../constants/ActionTypes";

export function setPackagesData(values) {
  return (dispatch) => {
    dispatch({
      type: SET_PACKAGE_DATA,
      payload: values,
    });
  };
}

export function setPackagesTypeData(values) {
  return (dispatch) => {
    dispatch({
      type: SET_PACKAGE_TYPE_DATA,
      payload: values,
    });
  };
}

export function EditPackageFlag(values) {
  return (dispatch) => {
    dispatch({
      type: EDIT_PACKAGE_FLAG,
      payload: values,
    });
  };
}

export function EditPackageRecord(values) {
  return (dispatch) => {
    dispatch({
      type: EDIT_PACKAGE_RECORD,
      payload: values,
    });
  };
}

export function setDiscountIn(values) {
  return (dispatch) => {
    dispatch({
      type: SET_DISCOUNT_IN,
      payload: values,
    });
  };
}

export function setDiscountPercentage(values) {
  return (dispatch) => {
    dispatch({
      type: SET_DISCOUNT_PERCENTAGE,
      payload: values,
    });
  };
}

export function setServicesGroupWiseNotAssignedData(values, isExtended) {
  return (dispatch) => {
    dispatch({
      type: SET_SERVICES_GROUP_WISE_NOT_ASSIGNED_DATA,
      payload: values,
      isExtended: isExtended,
    });
  };
}
