import axios from "axios";

export const baseUrl = `https://api.ehealthmatrix.com/api/`;
const baseUrl2 = `https://api.ehealthmatrix.com/`;

// export const baseUrl = `https://hmisapidev.ssasoft.com/HMS/api/`;
// const baseUrl2 = `https://hmisapidev.ssasoft.com/HMS/`;

// export const baseUrl = `https://hmisapidev.ssasoft.com/zafar/api/`;
// const baseUrl2 = `https://hmisapidev.ssasoft.com/zafar/`;

// export const baseUrl = `https://zafarapi.ssasoft.com/api/`;
// const baseUrl2 = `https://zafarapi.ssasoft.com/`;

// https://hmisapidev.ssasoft.com/zafar/swagger/index.html

// QA Testing
// export const baseUrl = `https://hmisqaapi.ssasoft.com/api/`;
// const baseUrl2 = `https://hmisqaapi.ssasoft.com/`;

// export const baseUrl = `https://hmisapidev.ssasoft.com/api/`;
// const baseUrl2 = `https://hmisapidev.ssasoft.com/`;

const requestHeader = {
  "Content-Type": "application/json",
};

const requestFormHeader = {
  "Content-Type": "multipart/form-data",
  Authorization: "Bearer " + localStorage.getItem("token"),
};

export const httpClient = axios.create({
  baseURL: baseUrl,
  headers: requestHeader,
});

export const httpClient2 = axios.create({
  baseURL: baseUrl2,
  withCredentials: true,
});

export const httpClient3 = axios.create({
  baseURL: baseUrl,
  headers: requestFormHeader,
});
