export const ROUTES = {
  "/dashboard": "./SamplePage",
  "/addGroup": "./hospital-hierarchy/AddGroupPage",
  "/addHospital": "./AddHospitalPage",
  "/generalConfigurations": "./GeneralConfigurations",
  "/addDepartment": "./AddDepartmentPage",
  "/addSubDepartment": "./AddSubDepartmentPage",
  "/createLogin": "./CreateLoginPage",
  "/assignUserRoles": "./UserRolesPage",
  "/addMainMenu": "./AddMenuPage",
  "/pagesSetup": "./PagesSetupPage",
  "/addRole": "./AddRolesPage",
  "/assignModulesToRoles": "./AssignModulesToRolesPage",
  "/rollingBackRole": "./RollingBackRolePage",
  "/employeeRegistration": "./administration/EmployeeRegistrationPage",
  "/patientRegistration": "./RegisterPatientPage",
  "/editPatientInfo": "./EditPatientRecordPage",
  "/membershipCard": "./MembershipCard",
  "/patientPrintPrescription": "./PatientPrintPrescriptionPage",
  "/favoritesDiagnosis": "./FavDiagnosisPage",
  "/favoritesMedication": "./FavrtMedPage",
  "/favoritesProcedure": "./FavrtProcPage",
  "/reportingtemplate": "./ReportingTemplatesPage",
  "/serviceRateSetup": "./administration/ServiceRateSetupPage",
  "/serviceSetup": "./administration/ServiceSetupPage",
  "/serviceMainGroup": "./administration/ServiceMainGroupPage",
  "/serviceSubGroup": "./administration/ServiceSubGroupPage",
  "/favoriteProcedure": "./FavrtProcPage",
  "/templateSetup": "./TemplateSetupPage",
  "/patientVitals": "./PatientVitalsPage",
  "/triagePatientRegister": "./TriagePatientRegistrPage",
  "/admissionAndObservation": "./AdmissionAndObservationPage",
  "/patientHistory": "./PatientHistoryPage",
  "/referrals": "./ReferralsPage",
  "/patientDiagnosis": "./PatientDiagnosisPage",
  "/addPanel": "./administration/Panel&Packages/PanelPage",
  "/packageSetup": "./administration/Panel&Packages/PackagePage",
  "/assignServiceToPackage":
    "./administration/Panel&Packages/AssignServiceToPackage",
  "/patientInvestigation": "./PatientInvestigationPage",
  "/favoritesInvestigation": "./FavrtInvestPage",
  "/alertsAllergy": "./AlertsAllergy",
  "/shiftManagement": "./ShiftManagementPage",
  "/setPatientDiscountPolicy":
    "./administration/discount-policy/SetPatientDiscountPage",
  "/changePatientDiscountPolicy":
    "./administration/discount-policy/ChangeDiscountSettingPage",
  "/cashType": "./ManageExpenseMenu/CashInOutTypePage",
  "/cashVoucher": "./ManageExpenseMenu/CashInOutVoucherPage",
  "/dailyCashClosing": "./ManageExpenseMenu/DailyCashClosingPage",
  "/userLicenseAgreement": "./LicenseAgreementPage",
  "/eTreatment": "./patient-management/ETreatment",
  "/DrDashboard": "./DrDashboardPage",
  "/NurseDashboard": "./NurseDashBoardPage",
  "/TemplateRetrieval": "./TemplatesRetrievalPage",
  "/doctorAppointmentScheduling": "./appointments/dr-appointments-scheduling",
  "/AppointmentList": "./appointments/appointment-list",
  "/markDuty": "./MarkDutyPage",
  "/dutySetting": "./DutySettingPage",
  "/WardsRoomBedSetup": "./WardsRoomBedSetupPage",
  "/printPrescription": "./PrintPrescriptionPage",
  "/procedures": "./ProceduresPage",
  "/opdPatientBilling": "./PatientBillingPage",
  "/cashBook": "./CashBookPage",
  "/discountApproval": "./DiscountApprovalPage",
  "/refundApproval": "./RefundApprovalPage",
  "/approvedRefunds": "./ApprovedRefundsPage",
  "/processMovement": "./ProcessMovementPage",
  "/dressingRoom": "./DressingRoomPage",
  "/itemRegistration": "./PharmacyModule/ManageProductPage",
  "/managePrescriptions": "./PharmacyModule/ManagePrescriptions",
  "/addNewSupplier": "./PharmacyModule/ManageSupplierPage",
  "/manageGrn": "./PharmacyModule/ManageGrnPage",
  "/OpeningStockPage": "./PharmacyModule/OpeningStockPage",
  "/manageVoucher": "./PharmacyModule/ManageVoucherPage",
  "/manageInvoices": "./PharmacyModule/ManageInvoicesPage",
  "/returnInvoice": "./PharmacyModule/ManageReturnInvoicePage",
  "/fixSaleRate": "./PharmacyModule/ManageFixSaleRatePage",
  "/internalStockDemand": "./ProcurementModule/InternalStockDemandPage",
  "/managePO": "./ProcurementModule/ManagePOPage",
  "/manageIGP": "./ProcurementModule/ManageIGPPage",
  "/aggregateStockDemand": "./ProcurementModule/AggregateStockDemand",
  "/requestForQuotation": "./ProcurementModule/RequestForQuotation",
  "/quotationEvaluation": "./ProcurementModule/QuotationEvaluation",
  "/manageStn": "./ProcurementModule/ManageSTN",
  "/stockInHandPage": "./Reports/StockInHandPage",
  "/dailySaleReport": "./Reports/DailySaleReport",
  "/patientObservationNurse": "./NurseObservationPage",
  "/patientObservationDoctor": "./DoctorObservationPage",
  "/deathCertificate": "./DeathCertificatePage",
  "/patientComplaintsAndExamination": "./ComplaintsAndExaminationHistoryPage",
  "/pages": "./AssignPages",
  "/wardRoomBedSetup": "./WardsRoomBedSetupPage",
};
