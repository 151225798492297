import {
  ADDED_FAV_DIAGNOSIS,
  REMOVE_FAV_DIAGNOSIS,
} from "../../constants/ActionTypes";

const INIT_STATE = {
  addedFavDiag: [],
  isDataLoaded: false,
};

const FavDiagnosis = (state = INIT_STATE, action) => {
  switch (action.type) {
    case ADDED_FAV_DIAGNOSIS: {
      return {
        ...state,
        addedFavDiag: action.payload,
        isDataLoaded: true,
      };
    }
    case REMOVE_FAV_DIAGNOSIS: {
      var { favDiagnosis } = state;
      var filteredResults;
      filteredResults = favDiagnosis.filter(
        (record) => record.sr !== action.payload
      );

      return {
        ...state,
        favDiagnosis: filteredResults,
      };
    }
    default:
      return state;
  }
};

export default FavDiagnosis;
