import _ from 'lodash';

export const validateInput = (inputs = []) => {
    var isValid = true;
    inputs.map((input, index)=>{
        if( input == '' || input == undefined || input == null) return isValid = false;
    })
    return isValid;
};


