import { SET_GROUPS_RECORD, EDIT_GROUP_FLAG, EDIT_GROUP_RECORD, SAVE_EDITED_GROUP_RECORD } from '../../../constants/ActionTypes';


export function setGroupsData(values) {
    return (dispatch) => {
      dispatch({
        type: SET_GROUPS_RECORD,
        payload: values
      });
    }
}

export function EditGroupFlag(values) {
    return (dispatch) => {
      dispatch({
        type: EDIT_GROUP_FLAG,
        payload: values
      });
    }
}

export function EditGroupRecord(values) {
    return (dispatch) => {
      dispatch({
        type: EDIT_GROUP_RECORD,
        payload: values
      });
    }
}

export function SaveEditedGroupRecord(values) {
  return (dispatch) => {
    dispatch({
      type: SAVE_EDITED_GROUP_RECORD,
      payload: values
    });
  }
}