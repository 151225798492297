// Customizer const
export const TOGGLE_COLLAPSED_NAV = "TOGGLE_COLLAPSE_MENU";
export const WINDOW_WIDTH = "WINDOW-WIDTH";
export const SWITCH_LANGUAGE = "SWITCH-LANGUAGE";

//Contact Module const
export const FETCH_START = "fetch_start";
export const FETCH_SUCCESS = "fetch_success";
export const FETCH_ERROR = "fetch_error";
export const SHOW_MESSAGE = "SHOW_MESSAGE";
export const HIDE_MESSAGE = "HIDE_MESSAGE";
export const ON_SHOW_LOADER = "ON_SHOW_LOADER";
export const ON_HIDE_LOADER = "ON_HIDE_LOADER";

//Auth const
export const SIGNUP_USER = "SIGNUP_USER";
export const SIGNUP_USER_SUCCESS = "SIGNUP_USER_SUCCESS";
export const SIGNIN_GOOGLE_USER = "SIGNIN_GOOGLE_USER";
export const SIGNIN_GOOGLE_USER_SUCCESS = "SIGNIN_GOOGLE_USER_SUCCESS";
export const SIGNIN_FACEBOOK_USER = "SIGNIN_FACEBOOK_USER";
export const SIGNIN_FACEBOOK_USER_SUCCESS = "SIGNIN_FACEBOOK_USER_SUCCESS";
export const SIGNIN_TWITTER_USER = "SIGNIN_TWITTER_USER";
export const SIGNIN_TWITTER_USER_SUCCESS = "SIGNIN_TWITTER_USER_SUCCESS";
export const SIGNIN_GITHUB_USER = "SIGNIN_GITHUB_USER";
export const SIGNIN_GITHUB_USER_SUCCESS = "signin_github_user_success";
export const SIGNIN_USER = "SIGNIN_USER";
export const SIGNIN_USER_SUCCESS = "SIGNIN_USER_SUCCESS";
export const SIGNOUT_USER = "SIGNOUT_USER";
export const SIGNOUT_USER_SUCCESS = "SIGNOUT_USER_SUCCESS";
export const INIT_URL = "INIT_URL";
export const USER_DATA = "user_data";
export const USER_TOKEN_SET = "user_token_set";
export const UPDATE_LOAD_USER = "UPDATE_LOAD_USER";

// Authenticate User
export const AUTHENTICATED_USER = "AUTHENTICATED_USER";

//  Add Group const
export const SWITCH_ADD_HOSPITAL_PAGE = "SWITCH_UPDATE_ADD_HOSPITAL_PAGE";
export const SET_GROUPS_RECORD = "SET_GROUPS_RECORD";
export const EDIT_GROUP_FLAG = "EDIT_GROUP_FLAG";
export const EDIT_GROUP_RECORD = "EDIT_GROUP_RECORD";
export const SAVE_EDITED_GROUP_RECORD = "SAVE_EDITED_GROUP_RECORD";

// Add Hospital const
export const SET_HOSPITALS_RECORD = "SET_HOSPITALS_RECORD";
export const SET_MAIN_GROUP_ID = "SET_MAIN_GROUP_ID";
export const EDIT_HOSPITAL_FLAG = "EDIT_HOSPITAL_FLAG";
export const EDIT_HOSPITAL_RECORD = "EDIT_HOSPITAL_RECORD";
export const SAVE_EDITED_HOSPITAL_RECORD = "SAVE_EDITED_HOSPITAL_RECORD";

// Add Department const
export const SET_DEPARTMENT_DATA = "SET_DEPARTMENT_DATA";
export const EDIT_DEPARTMENT_FLAG = "EDIT_DEPARTMENT_FLAG";
export const EDIT_DEPARTMENT_RECORD = "EDIT_DEPARTMENT_RECORD";
export const SAVE_EDITED_DEPARTMENT_RECORD = "SAVE_EDITED_DEPARTMENT_RECORD";

// Add Sub Department const
export const SET_SUB_DEPARTMENT_DATA = "SET_SUB_DEPARTMENT_DATA";
export const SET_SUB_DEPARTMENT_ISLOADED = "SET_SUB_DEPARTMENT_ISLOADED";
export const EDIT_SUB_DEPARTMENT_FLAG = "EDIT_SUB_DEPARTMENT_FLAG";
export const EDIT_SUB_DEPARTMENT_RECORD = "EDIT_SUB_DEPARTMENT_RECORD";
export const SAVE_EDITED_SUB_DEPARTMENT_RECORD =
  "SAVE_EDITED_SUB_DEPARTMENT_RECORD";

// Patient Visits const
export const CONFIRM_PATIENT_VISIT = "CONFIRM_PATIENT_VISIT";
export const CONFIRM_PATIENT_REVISIT = "CONFIRM_PATIENT_REVISIT";
export const PATIENT_REVISIT_RECORD = "PATIENT_REVISIT_RECORD";
export const CLEAR_PTNT_REVISIT_RECORD = "CLEAR_PTNT_REVISIT_RECORD";
export const EDIT_PATIENT_RECORD = "EDIT_PATIENT_RECORD";
export const CLEAR_EDIT_PTNT_RECORD = "CLEAR_EDIT_PTNT_RECORD";
export const SEARCH_PATIENT = "SEARCH_PATIENT";
export const PATIENT_SEARCH_RECORDS = "PATIENT_SEARCH_RECORDS";
export const UPDATE_PATIENT_VISIT = "UPDATE_PATIENT_VISIT";
export const SET_PATIENT_CATEGORIES = "SET_PATIENT_CATEGORIES";

// Create Login Users const
export const CREATE_LOGIN_USER = "CREATE_LOGIN_USER";

// Favorites Diagnosis
export const ADDED_FAV_DIAGNOSIS = "ADDED_FAV_DIAGNOSIS";
export const REMOVE_FAV_DIAGNOSIS = "REMOVE_FAV_DIAGNOSIS";

// Favorites Investigation
export const ADDED_FAV_INVEST = "ADDED_FAV_INVEST";
export const REMOVE_FAV_INVEST = "REMOVE_FAV_INVEST";

// Favorites Medication
export const ADDED_FAV_MEDICATION = "ADDED_FAV_MEDICATION";
export const REMOVE_FAV_MEDICATION = "REMOVE_FAV_MEDICATION";

//Menu And Pages const
export const SET_MENU_RECORD = "SET_MENU_RECORD";
export const SET_MENU_PAGE_RECORD = "SET_MENU_PAGE_RECORD";
export const EDIT_MENU_FLAG = "EDIT_MENU_FLAG";
export const EDIT_MENU_RECORD = "EDIT_MENU_RECORD";
export const EDIT_PAGE_FLAG = "EDIT_PAGE_FLAG";
export const EDIT_PAGE_RECORD = "EDIT_PAGE_RECORD";
export const SET_SELECTED_PAGES = "SET_SELECTED_PAGES";

//employee const
export const SET_EMPLOYEE_DATA = "SET_EMPLOYEE_DATA";
export const EDIT_EMPLOYEE_FLAG = "EDIT_EMPLOYEE_FLAG";
export const EDIT_EMPLOYEE_RECORD = "EDIT_EMPLOYEE_RECORD";
export const SET_EMPLOYEE_FORM_DATA = "SET_EMPLOYEE_FORM_DATA";
export const SET_EDIT_EMPLOYEE_FORM_DATA = "SET_EDIT_EMPLOYEE_FORM_DATA";

//role const
export const SET_ROLE_DATA = "SET_ROLE_DATA";
export const EDIT_ROLE_FLAG = "EDIT_ROLE_FLAG";
export const EDIT_ROLE_RECORD = "EDIT_ROLE_DATA";

//create login and role const
export const SET_CREATE_LOGIN_DATA = "SET_CREATE_LOGIN_DATA";
export const EDIT_CREATE_LOGIN_FLAG = "EDIT_CREATE_LOGIN_FLAG";
export const EDIT_CREATE_LOGIN_RECORD = "EDIT_CREATE_LOGIN_RECORD";
// Favorites Procedure
export const ADDED_FAV_PROCEDURE = "ADDED_FAV_PROCEDURE";
export const REMOVE_FAV_PROCEDURE = "REMOVE_FAV_PROCEDURE";

// billing const
export const SET_SERVICE_MAIN_GROUP_DATA = "SET_SERVICE_MAIN_GROUP_DATA";

export const SET_SERVICE_SUB_GROUP_DATA = "SET_SERVICE_SUB_GROUP_DATA";
export const EDIT_SERVICE_SUB_GROUP_FLAG = "EDIT_SERVICE_SUB_GROUP_FLAG";
export const EDIT_SERVICE_SUB_GROUP_RECORD = "EDIT_SERVICE_SUB_GROUP_RECORD";
// Template Assignment
export const ASSIGN_TEMPLATE = "ASSIGN_TEMPLATE";
export const REMOVE_TEMPLATE = "REMOVE_TEMPLATE";

export const SET_SERVICES_DATA = "SET_SERVICES_DATA";
export const EDIT_SERVICE_FLAG = "EDIT_SERVICE_FLAG";
export const EDIT_SERVICE_RECORD = "EDIT_SERVICE_RECORD";

export const SET_SERVICE_RATE_DATA = "SET_SERVICE_RATE_DATA";
export const SET_SERVICE_RATE_FILTERED_DATA = "SET_SERVICE_RATE_FILTERED_DATA";
export const SET_SERVICE_RATE_TYPE = "SET_SERVICE_RATE_TYPE";
export const EDIT_SERVICE_RATE_FLAG = "EDIT_SERVICE_RATE_FLAG";
export const EDIT_SERVICE_RATE_RECORD = "EDIT_SERVICE_RATE_RECORD";

export const SET_SERVICE_LATERALITY_DATA = "SET_SERVICE_LATERALITY_DATA";
export const SET_RATE_TYPES_DATA = "SET_RATE_TYPES_DATA";

//country & city const
export const SET_COUNTRIES_DATA = "SET_COUNTRIES_DATA";
export const SET_CITIES_DATA = "SET_CITIES_DATA";
export const SET_COUNTRY_VALUE = "SET_COUNTRY_VALUE";

// assign roles to user
export const SET_ASSIGNED_ROLE_TO_USER_DATA = "SET_ASSIGNED_ROLE_TO_USER_DATA";
export const EDIT_ASSIGNED_ROLE_TO_USER_FLAG =
  "EDIT_ASSIGNED_ROLE_TO_USER_FLAG";
export const EDIT_ASSIGNED_ROLE_TO_USER_RECORD =
  "EDIT_ASSIGNED_ROLE_TO_USER_RECORD";

// panel & packages const
export const SET_PANEL_DATA = "SET_PANEL_DATA";
export const EDIT_PANEL_FLAG = "EDIT_PANEL_FLAG";
export const EDIT_PANEL_RECORD = "EDIT_PANEL_RECORD";

export const SET_PACKAGE_DATA = "SET_PACKAGE_DATA";
export const SET_PACKAGE_TYPE_DATA = "SET_PACKAGE_TYPE_DATA";
export const EDIT_PACKAGE_FLAG = "EDIT_PACKAGE_FLAG";
export const EDIT_PACKAGE_RECORD = "EDIT_PACKAGE_RECORD";

export const SET_SERVICES_GROUP_WISE_NOT_ASSIGNED_DATA =
  "SET_SERVICES_GROUP_WISE_NOT_ASSIGNED_DATA";
export const SET_DISCOUNT_IN = "SET_DISCOUNT_IN";
export const SET_DISCOUNT_PERCENTAGE = "SET_DISCOUNT_PERCENTAGE";

// assign Modules to roles
export const SET_ASSIGNED_MODULES_TO_ROLES_DATA =
  "SET_ASSIGNED_MODULES_TO_ROLES_DATA";
export const EDIT_ASSIGNED_MODULES_TO_ROLES_FLAG =
  "EDIT_ASSIGNED_MODULES_TO_ROLES_FLAG";
export const EDIT_ASSIGNED_MODULES_TO_ROLES_RECORD =
  "EDIT_ASSIGNED_MODULES_TO_ROLES_RECORD";
export const LOAD_ASSIGNED_MODULES_TO_ROLES_RECORD =
  "LOAD_ASSIGNED_MODULES_TO_ROLES_RECORD";
export const LOAD_ASSIGNED_MODULES_TO_ROLES_FLAG =
  "LOAD_ASSIGNED_MODULES_TO_ROLES_FLAG";

// assign Modules to roles
export const SET_SINGLE_USER_ROLE_DATA = "SET_SINGLE_USER_ROLE_DATA";
export const SET_SINGLE_USER_ROLE_MENU_PAGE_DATA =
  "SET_SINGLE_USER_ROLE_MENU_PAGE_DATA";

// discount-policy const
export const SET_PATIENT_CATEGORY_DATA = "SET_PATIENT_CATEGORY_DATA";

// Patient Diagnosis
export const ADD_DIAG_SESSION_DATA = "ADD_DIAG_SESSION_DATA";
export const CLEAR_DIAG_SESSION_DATA = "CLEAR_DIAG_SESSION_DATA";
export const REMOVE_DIAG_FROM_SESSION = "REMOVE_DIAG_FROM_SESSION";
export const ADDED_VISIT_PATIENT_DIAGS = "ADDED_VISIT_PATIENT_DIAGS";

// Patient Investigation
export const ADD_INVEST_SESSION_DATA = "ADD_INVEST_SESSION_DATA";

export const CLEAR_INVEST_SESSION_DATA = "CLEAR_INVEST_SESSION_DATA";
export const REMOVE_INVEST_FROM_SESSION = "REMOVE_INVEST_FROM_SESSION";
export const ADDED_VISIT_PATIENT_INVEST = "ADDED_VISIT_PATIENT_INVEST";

// Template Setup
export const ADDED_TEMPLATES = "ADDED_TEMPLATES";
export const EDIT_TEMPLATE = "EDIT_TEMPLATE";
export const ADDED_MG_FOR_TEMPLATE = "ADDED_MG_FOR_TEMPLATE";
export const EDIT_MAIN_GROUP = "EDIT_MAIN_GROUP";
export const CLEAR_EDIT_MAIN_GROUP = "CLEAR_EDIT_MAIN_GROUP";
export const ADDED_MH_FOR_TEMP_AND_MG = "ADDED_MH_FOR_TEMP_AND_MG";
export const EDIT_MAIN_HEADING = "EDIT_MAIN_HEADING";
export const ADDED_HEADINGS = "ADDED_HEADINGS";
export const EDIT_HEADING = "EDIT_HEADING";
export const ADDED_ELEMENTS = "ADDED_ELEMENTS";
export const EDIT_ELEMENT = "EDIT_ELEMENT";
export const TEMPLATE_TABS = "TEMPLATE_TABS";

//Admission
export const ADD_ADMISSION = "ADD_ADMISSION";
export const SET_WARDS = "SET_WARDS";

//Observation
export const ADD_OBSERVATION = "ADD_OBSERVATION";
export const SET_OBSERVATION_ROOMS = "SET_OBSERVATION_ROOMS";

//Login
export const SET_LOGIN = "SET_LOGIN";

//Investigation_Visit_History
export const SET_LAB_VISIT_HISTORY = "SET_LAB_VISIT_HISTORY";
export const SET_RAD_VISIT_HISTORY = "SET_RAD_VISIT_HISTORY";

//Shifts Management
export const SET_SHIFTS = "SET_SHIFTS";
export const EDIT_SHIFTS = "EDIT_SHIFTS";

//Register Patient Visits
export const SET_PATIENT_TOKEN = "SET_PATIENT_TOKEN";
export const SET_PATIENT_VISIT = "SET_PATIENT_VISIT";

//DrDashboard
export const SET_CURRENT_PATIENT_TYPE = "SET_CURRENT_PATIENT_TYPE";
export const SET_WAITING_PATIENT = "SET_WAITING_PATIENT";
export const SET_ATTENDED_PATIENT = "SET_ATTENDED_PATIENT";
export const SET_REFERRAL_PATIENT = "SET_REFERRAL_PATIENT";
export const SET_ADMITTED_PATIENT = "SET_ADMITTED_PATIENT";
export const SET_OBSERVATION_PATIENT = "SET_OBSERVATION_PATIENT";
export const SET_TOTAL_WAITING_PATIENT = "SET_TOTAL_WAITING_PATIENT";
export const SET_TOTAL_ATTENDED_PATIENT = "SET_TOTAL_ATTENDED_PATIENT";
export const SET_TOTAL_REFERRAL_PATIENT = "SET_TOTAL_REFERRAL_PATIENT";
export const SET_TOTAL_ADMITTED_PATIENT = "SET_TOTAL_ADMITTED_PATIENT";
export const SET_TOTAL_OBSERVATION_PATIENT = "SET_TOTAL_OBSERVATION_PATIENT";
export const SET_PATIENT_MR_AND_VISITID = "SET_PATIENT_MR_AND_VISITID";

//Procurement
export const SET_UNIQUE_COUNTER = "SET_UNIQUE_COUNTER";
export const INCREMENT_UNIQUE_COUNTER = "INCREMENT_UNIQUE_COUNTER";

//PatientHistory
export const SET_PATIENT_DEMOGRAPHIC_DATA = "SET_PATIENT_DEMOGRAPHIC_DATA";
export const SET_PATIENT_DIAGNOSIS_HISTORY = "SET_PATIENT_DIAGNOSIS_HISTORY";
export const SET_PATIENT_INVESTIGATION_LAB_HISTORY =
  "SET_PATIENT_INVESTIGATION_LAB_HISTORY";
export const SET_PATIENT_INVESTIGATION_RAD_HISTORY =
  "SET_PATIENT_INVESTIGATION_RAD_HISTORY";
export const SET_PATIENT_MEDICATION_HISTORY = "SET_PATIENT_MEDICATION_HISTORY";
export const SET_PATIENT_ERM_HISTORY = "SET_PATIENT_ERM_HISTORY";
export const SET_PATIENT_PROCEDURE_HISTORY = "SET_PATIENT_PROCEDURE_HISTORY";
export const SET_PATIENT_OUTCOME_HISTORY = "SET_PATIENT_OUTCOME_HISTORY";
export const SET_PATIENT_ADMISSION_HISTORY = "SET_PATIENT_ADMISSION_HISTORY";
export const SET_PATIENT_SEARCH_HISTORY = "SET_PATIENT_SEARCH_HISTORY";

//patient vitals
export const SET_VITALS_TEMPLATE = "SET_VITALS_TEMPLATE";
export const SET_VITALS_TEMPLATE_IS_LOADING = "SET_VITALS_TEMPLATE_IS_LOADING";
export const SET_ASSIGN_MAIN_GROUP = "SET_ASSIGN_MAIN_GROUP";
export const SET_ASSIGN_MAIN_HEADING = "SET_ASSIGN_MAIN_HEADING";
export const SET_ASSIGN_HEADING = "SET_ASSIGN_HEADING";
export const SET_ASSIGN_ELEMENT = "SET_ASSIGN_ELEMENT";

//Template Retrieval
export const SET_ASSIGN_TEMPLATE = "SET_ASSIGN_TEMPLATE";
export const SET_ASSIGN_MAIN_GROUP_TEMPLATE_RETRIEVAL =
  "SET_ASSIGN_MAIN_GROUP_TEMPLATE_RETRIEVAL";
export const SET_TEMPLATES_TABLE_DATA = "SET_TEMPLATES_TABLE_DATA";
export const SET_TEMPLATES_TABLE_EDIT_DATA = "SET_TEMPLATES_TABLE_EDIT_DATA";
export const CLEAR_TEMPLATES_TABLE_STATE = "CLEAR_TEMPLATES_TABLE_STATE";
export const SET_TEMPLATES_TABLE_RECORD = "SET_TEMPLATES_TABLE_RECORD";
export const SET_SELECTED_TEMPLATE_ID = "SET_SELECTED_TEMPLATE_ID";
export const SET_SELECTED_MAIN_GROUP_ID = "SET_SELECTED_MAIN_GROUP_ID";
export const SET_SELECTED_MAIN_HEADING_ID = "SET_SELECTED_MAIN_HEADING_ID";
export const SET_SELECTED_HEADING_ID = "SET_SELECTED_HEADING_ID";
export const SET_MAIN_HEADING_DATA = "SET_MAIN_HEADING_DATA";
export const SET_HEADING_DATA = "SET_HEADING_DATA";

//LogOut
export const LOGOUT = "LOGOUT";

// Nurse_Observation
export const OBSERVATION_ACCESSED = "OBSERVATION_ACCESSED";
export const OBSERVATION_ACCESSED_DATA = "OBSERVATION_ACCESSED_DATA";

// Doctor_Observation
export const OBSERVATION_ACCESSED_DOCTOR = "OBSERVATION_ACCESSED_DOCTOR";

// Patient_Billing
export const BILLING_ACCESSED = "BILLING_ACCESSED";
export const BILLING_ACCESSED_DATA = "BILLING_ACCESSED_DATA";
export const BILLING_PATIENTS_DATA = "BILLING_PATIENTS_DATA";
export const BILLING_PATIENT_INVOICE_DATA = "BILLING_PATIENT_INVOICE_DATA";
