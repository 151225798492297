import {
  ADD_INVEST_SESSION_DATA,
  CLEAR_INVEST_SESSION_DATA,
  REMOVE_INVEST_FROM_SESSION,
  ADDED_VISIT_PATIENT_INVEST,
} from "../../constants/ActionTypes";

const INIT_STATE = {
  investFormData: [],
  // addedVisitPtntDiags: [],
};

const PatientInvest = (state = INIT_STATE, action) => {
  switch (action.type) {
    case ADD_INVEST_SESSION_DATA: {
      return {
        ...state,
        investFormData: [...state.investFormData, action.payload],
      };
    }
    case CLEAR_INVEST_SESSION_DATA: {
      return {
        ...state,
        investFormData: [],
      };
    }
    case REMOVE_INVEST_FROM_SESSION: {
      const investigation = action.payload;

      const newInvestFormData = state.investFormData.filter(
        (data) => data.investigation !== investigation
      );

      return {
        ...state,
        investFormData: newInvestFormData,
      };
    }
    // case ADDED_VISIT_PATIENT_DIAGS: {
    //   return {
    //     ...state,
    //     addedVisitPtntDiags: action.payload,
    //   };
    // }
    default:
      return state;
  }
};

export default PatientInvest;
