import { httpClient, httpClient2 } from "./Api";
import { message, message as toastNotification } from "antd";

const showUrl = false;

const directResponse = ["isOpen", "OpeningCash", "PaymentMainID"];

const handleDirectResponseObject = (obj) => {
  return directResponse.some((objProperty) => {
    return obj.hasOwnProperty(objProperty);
  });
};

const typedResponse = (type, callbackFun, data, url) => {
  if (callbackFun) {
    if (type === "data") {
      if (Array.isArray(data)) {
        callbackFun(data);
      } else if (data === "No Record Found") {
        callbackFun([]);
        if (showUrl) message.warn(`${url} Empty Array Sent`);
      } else if (handleDirectResponseObject(data)) {
        callbackFun(data);
      } else if (handleDirectResponseObject(data["Value"])) {
        callbackFun(data["Value"]);
      } else if (
        data["Value"]["table"] &&
        Array.isArray(data["Value"]["table"])
      ) {
        callbackFun(data["Value"]["table"]);
      } else if (
        data["Value"]["tableMG"] &&
        Array.isArray(data["Value"]["tableMG"])
      ) {
        callbackFun(data["Value"]["tableMG"]);
      } else {
        callbackFun([]);
        if (showUrl) message.warn(`${url} Empty Array Sent`);
      }
    } else if (type === "msg") {
      if (data && typeof data === "string") {
        callbackFun(data);
      } else if (data["Value"] && typeof data["Value"] === "string") {
        callbackFun(data["Value"]);
      } else if (data["Value"]["VisitID"]) {
        callbackFun(data["Value"]);
      } else if (
        data["Value"]["Msg"] &&
        typeof data["Value"]["Msg"] === "string"
      ) {
        callbackFun(data["Value"]["Msg"]);
      } else if (
        data["Value"]["msg"] &&
        typeof data["Value"]["msg"] === "string"
      ) {
        callbackFun(data["Value"]["msg"]);
      } else if (
        data["value"]["Msg"] &&
        typeof data["value"]["Msg"] === "string"
      ) {
        callbackFun(data["value"]["Msg"]);
      } else if (
        data["value"]["msg"] &&
        typeof data["value"]["msg"] === "string"
      ) {
        callbackFun(data["value"]["msg"]);
      } else {
        callbackFun("No Message Found");
        if (showUrl) message.warn(`${url} No Message Found`);
      }
    }
  }
};

export const getApi = (url, callbackFun, type, getLogout, loading) => {
  // const history = useHistory();

  if (loading) message.loading("Please Wait...", 0);
  httpClient
    .get(url)
    .then(({ data }) => {
      if (loading) message.destroy();
      typedResponse(type, callbackFun, data, url);
    })
    .catch(function (error) {
      // console.log("erroor", error.response.data.message);
      // if (
      //   error?.response?.data?.message ===
      //   "Session has expired. Please log in again."
      // ) {
      //   console.log("error sucess");
      // }
      onErrorCatch(error, loading, getLogout, url);
    });
};

export const getApi2 = (url, callbackFun, type, getLogout, loading) => {
  httpClient2.defaults.headers.common["Authorization"] =
    "Bearer " + localStorage.getItem("token");
  if (loading) message.loading("Please Wait...", 0);
  httpClient2
    .get(url)
    .then(({ data }) => {
      if (loading) message.destroy();
      typedResponse(type, callbackFun, data, url);
    })
    .catch(function (error) {
      onErrorCatch(error, loading, getLogout, url);
    });
};

export const postApi = (
  url,
  body,
  callbackFun,
  type,
  getLogout,
  loading,
  showSuccess
) => {
  if (loading) message.loading("Please Wait...", 0);
  if (body) {
    httpClient
      .post(url, body)
      .then(({ data }) => {
        if (loading) message.destroy();
        typedResponse(type, callbackFun, data, url);
      })
      .catch(function (error) {
        onErrorCatch(error, loading, getLogout, url);
      });
  } else {
    httpClient
      .post(url)
      .then(({ data }) => {
        if (loading) message.destroy();
        typedResponse(type, callbackFun, data, url);
      })
      .catch(function (error) {
        onErrorCatch(error, loading, getLogout, url);
      });
  }
};

export const putApi = (
  url,
  body,
  callbackFun,
  type,
  getLogout,
  loading,
  showSuccess
) => {
  if (loading) message.loading("Please Wait...", 0);
  if (body) {
    httpClient
      .put(url, body)
      .then(({ data }) => {
        if (loading) message.destroy();
        typedResponse(type, callbackFun, data, url);
      })
      .catch(function (error) {
        onErrorCatch(error, loading, getLogout, url);
      });
  } else {
    httpClient
      .put(url)
      .then(({ data }) => {
        if (loading) message.destroy();
        typedResponse(type, callbackFun, data, url);
      })
      .catch(function (error) {
        onErrorCatch(error, loading, getLogout, url);
      });
  }
};

export const deleteApi = (url, callbackFun, type, getLogout, loading) => {
  if (loading) message.loading("Please Wait...", 0);
  httpClient
    .delete(url)
    .then(({ data }) => {
      if (loading) message.destroy();
      typedResponse(type, callbackFun, data, url);
    })
    .catch(function (error) {
      onErrorCatch(error, loading, getLogout, url);
    });
};

const onErrorCatch = (error, loading, getLogout, url) => {
  if (
    error.response.data.message ===
      "Session has expired. Please log in again." ||
    error.response.data.message === "Token has expired."
  ) {
    message.warn(error.response.data.message);
    getLogout();
  }

  if (loading) message.destroy();
  // if (showUrl) message.error(`url: ${url}, message: ${error.message}`);
  // if (!showUrl) message.error(error.message);
};
