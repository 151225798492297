import {
  SET_HOSPITALS_RECORD,
  EDIT_HOSPITAL_FLAG,
  EDIT_HOSPITAL_RECORD,
  SAVE_EDITED_HOSPITAL_RECORD,
  SET_COUNTRY_VALUE,
  SET_MAIN_GROUP_ID,
} from "../../constants/ActionTypes";

const INIT_STATE = {
  hospitalsRecord: [],
  editFlag: false,
  editRecord: null,
  countryValue: "",
  mainGroupId: "",
};

const AddHospital = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_HOSPITALS_RECORD: {
      return {
        ...state,
        hospitalsRecord: action.payload,
      };
    }
    case EDIT_HOSPITAL_FLAG: {
      return {
        ...state,
        editFlag: action.payload,
      };
    }
    case EDIT_HOSPITAL_RECORD: {
      return {
        ...state,
        editRecord: action.payload,
      };
    }
    case SET_COUNTRY_VALUE: {
      return {
        ...state,
        countryValue: action.payload,
      };
    }
    case SAVE_EDITED_HOSPITAL_RECORD: {
      let index = state.addForm.findIndex((x) => x.sr === action.payload.sr);
      let temp = action.payload;
      action.payload = [...state.addForm];
      action.payload[index] = temp;
      return {
        ...state,
        addForm: action.payload,
      };
    }
    case SET_MAIN_GROUP_ID: {
      return {
        ...state,
        mainGroupId: action.payload,
      };
    }
    default:
      return state;
  }
};

export default AddHospital;
