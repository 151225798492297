import {SET_PANEL_DATA, EDIT_PANEL_FLAG, EDIT_PANEL_RECORD} from '../../../constants/ActionTypes';


export function setPanelsData(values) {
    return (dispatch) => {
      dispatch({
        type: SET_PANEL_DATA,
        payload: values
      });
    }
}



export function EditPanelFlag(values) {
  return (dispatch) => {
    dispatch({
      type: EDIT_PANEL_FLAG,
      payload: values
    });
  }
}

export function EditPanelRecord(values) {
  return (dispatch) => {
    dispatch({
      type: EDIT_PANEL_RECORD,
      payload: values
    });
  }
}

