import { SET_ASSIGNED_ROLE_TO_USER_DATA, EDIT_ASSIGNED_ROLE_TO_USER_FLAG, EDIT_ASSIGNED_ROLE_TO_USER_RECORD } from '../../../constants/ActionTypes';

const INIT_STATE = {
    assignRolesToUserData: [],
    editFlag:false,
    editRecord:null,
};


const AssignRolesToUser = (state = INIT_STATE, action) => {
    switch (action.type) {
      case SET_ASSIGNED_ROLE_TO_USER_DATA: {
        return {
          ...state,
          assignRolesToUserData: action.payload,
        }
      };
      case EDIT_ASSIGNED_ROLE_TO_USER_FLAG: {
        return {
          ...state,
          editFlag: action.payload,
        }
      };
      case EDIT_ASSIGNED_ROLE_TO_USER_RECORD: {
        return {
          ...state,
          editRecord: action.payload,
        }
      };
      default:
        return state;
    }
  }
  
export default AssignRolesToUser;
  