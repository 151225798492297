import {
  ADDED_FAV_INVEST,
  REMOVE_FAV_INVEST,
} from "../../constants/ActionTypes";

export function AddedFavInvest(values) {
  return (dispatch) => dispatch({ type: ADDED_FAV_INVEST, payload: values });
}

export function RemoveFavInvest(value) {
  return (dispatch) => dispatch({ type: REMOVE_FAV_INVEST, payload: value });
}
