import {
  ADDED_FAV_DIAGNOSIS,
  REMOVE_FAV_DIAGNOSIS,
} from "../../constants/ActionTypes";

export function AddedFavDiagnosis(values) {
  return (dispatch) => dispatch({ type: ADDED_FAV_DIAGNOSIS, payload: values });
}

export function RemoveFavDiagnosis(value) {
  return (dispatch) => dispatch({ type: REMOVE_FAV_DIAGNOSIS, payload: value });
}
