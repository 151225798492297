import {
  CONFIRM_PATIENT_VISIT,
  CONFIRM_PATIENT_REVISIT,
  // PATIENT_REVISIT_MR,
  PATIENT_REVISIT_RECORD,
  CLEAR_PTNT_REVISIT_RECORD,
  EDIT_PATIENT_RECORD,
  CLEAR_EDIT_PTNT_RECORD,
  SEARCH_PATIENT,
  PATIENT_SEARCH_RECORDS,
  UPDATE_PATIENT_VISIT,
  SET_PATIENT_CATEGORIES,
  // ADD_DIAGNOSIS_FAVORITE,
} from "../../constants/ActionTypes";

export function ConfirmPatientVisit(values) {
  return (dispatch) => {
    dispatch({
      type: CONFIRM_PATIENT_VISIT,
      payload: values,
    });
  };
}

export function ConfirmPatienReVisit(values) {
  return (dispatch) => {
    dispatch({
      type: CONFIRM_PATIENT_REVISIT,
      payload: values,
    });
  };
}

// export function PatienReVisitMr(value) {
//   return (dispatch) => {
//     dispatch({
//       type: PATIENT_REVISIT_MR,
//       payload: value,
//     });
//   };
// }

export function PatienReVisitRecord(values) {
  return (dispatch) => {
    dispatch({
      type: PATIENT_REVISIT_RECORD,
      payload: values,
    });
  };
}

export function ClearPatienReVisitRecord() {
  return (dispatch) => {
    dispatch({
      type: CLEAR_PTNT_REVISIT_RECORD,
    });
  };
}

export function EditPatientRecord(values) {
  return (dispatch) => {
    dispatch({
      type: EDIT_PATIENT_RECORD,
      payload: values,
    });
  };
}

export function ClearEditPtntRecord() {
  return (dispatch) => {
    dispatch({
      type: CLEAR_EDIT_PTNT_RECORD,
    });
  };
}

export function SearchPatient(value) {
  return (dispatch) => {
    dispatch({
      type: SEARCH_PATIENT,
      payload: value,
    });
  };
}

export function PatientSearchRecords(value) {
  return (dispatch) => {
    dispatch({
      type: PATIENT_SEARCH_RECORDS,
      payload: value,
    });
  };
}

export function setPatientCategories(value) {
  return (dispatch) => {
    dispatch({
      type: SET_PATIENT_CATEGORIES,
      payload: value,
    });
  };
}

export function UpdatePatientVisit(values) {
  const { mrNumber, record } = values;
  const numMrNumber = Number(mrNumber);

  values.record.mr = mrNumber;
  values.record.sr = numMrNumber;
  values.record.key = numMrNumber.toString();

  return (dispatch) => {
    dispatch({
      type: UPDATE_PATIENT_VISIT,
      payload: record,
    });
  };
}
