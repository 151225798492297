import {
  SET_PACKAGE_DATA,
  EDIT_PACKAGE_FLAG,
  EDIT_PACKAGE_RECORD,
  SET_PACKAGE_TYPE_DATA,
  SET_SERVICES_GROUP_WISE_NOT_ASSIGNED_DATA,
  SET_DISCOUNT_IN,
  SET_DISCOUNT_PERCENTAGE,
} from "../../../constants/ActionTypes";

const INIT_STATE = {
  packagesData: [],
  editFlag: false,
  editRecord: null,
  packageTypes: [],
  servicesGroupWiseNotAssignedData: [],
  discountIn: -1,
  discountPercentage: null,
};

const Packages = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_PACKAGE_DATA: {
      return {
        ...state,
        packagesData: action.payload,
      };
    }

    case EDIT_PACKAGE_FLAG: {
      return {
        ...state,
        editFlag: action.payload,
      };
    }

    case EDIT_PACKAGE_RECORD: {
      return {
        ...state,
        editRecord: action.payload,
      };
    }

    case SET_PACKAGE_TYPE_DATA: {
      return {
        ...state,
        packageTypes: action.payload,
      };
    }

    case SET_DISCOUNT_IN: {
      return {
        ...state,
        discountIn: action.payload,
      };
    }

    case SET_DISCOUNT_PERCENTAGE: {
      return {
        ...state,
        discountPercentage: action.payload,
      };
    }

    case SET_SERVICES_GROUP_WISE_NOT_ASSIGNED_DATA: {
      let extendedPayload = [];
      if (action.isExtended == false) {
        action.payload.map((item, index) => {
          let extItem = {
            ...item,
            sponsorRate: item.SERVICERATE,
            discountIn: "",
            discountPercentage: null,
            discountAmount: null,
            servicePackageRate: null,
            includeExclude: false,
            id: index,
          };
          extendedPayload.push(extItem);
        });
      }
      return {
        ...state,
        servicesGroupWiseNotAssignedData:
          action.isExtended == false ? extendedPayload : action.payload,
      };
    }

    default:
      return state;
  }
};

export default Packages;
