import {
    ADD_OBSERVATION,
    SET_OBSERVATION_ROOMS,
    OBSERVATION_ACCESSED,
    OBSERVATION_ACCESSED_DATA,
    OBSERVATION_ACCESSED_DOCTOR,
  } from "../../constants/ActionTypes";
  


  export function setObservationData(values) {
    return (dispatch) => dispatch({ type: ADD_OBSERVATION, payload: values });
  }

  export function setObservationRoomsData(values) {
    return (dispatch) => dispatch({ type: SET_OBSERVATION_ROOMS, payload: values });
  }

  export function observationAccessed(values) {
    return (dispatch) => dispatch({ type: OBSERVATION_ACCESSED, payload: values });
  }

  export function observationAccessedData(values) {
    return (dispatch) => dispatch({ type: OBSERVATION_ACCESSED_DATA, payload: values });
  }

  export function setObservationAccessedDoctor(values) {
    return (dispatch) => dispatch({ type: OBSERVATION_ACCESSED_DOCTOR, payload: values });
  }
