import { SET_SERVICE_SUB_GROUP_DATA, EDIT_SERVICE_SUB_GROUP_RECORD, EDIT_SERVICE_SUB_GROUP_FLAG} from '../../../constants/ActionTypes';


export function setServiceSubGroupData(values) {
    return (dispatch) => {
      dispatch({
        type: SET_SERVICE_SUB_GROUP_DATA,
        payload: values
      });
    }
}

export function EditServiceSubGroupFlag(values) {
  return (dispatch) => {
    dispatch({
      type: EDIT_SERVICE_SUB_GROUP_FLAG,
      payload: values
    });
  }
}

export function EditServiceSubGroupRecord(values) {
  return (dispatch) => {
    dispatch({
      type: EDIT_SERVICE_SUB_GROUP_RECORD,
      payload: values
    });
  }
}

