import {
  CONFIRM_PATIENT_VISIT,
  CONFIRM_PATIENT_REVISIT,
  PATIENT_REVISIT_RECORD,
  CLEAR_PTNT_REVISIT_RECORD,
  EDIT_PATIENT_RECORD,
  CLEAR_EDIT_PTNT_RECORD,
  SEARCH_PATIENT,
  PATIENT_SEARCH_RECORDS,
  UPDATE_PATIENT_VISIT,
  SET_PATIENT_CATEGORIES,
} from "../../constants/ActionTypes";

const INIT_STATE = {
  patientVisits: [],
  patientReVisits: [],
  patientReVisitRecord: {},
  editPatientRecord: {},
  patientSearchRecords: [],
  patientCategories: [],
};

const PatientVisits = (state = INIT_STATE, action) => {
  switch (action.type) {
    case CONFIRM_PATIENT_VISIT: {
      return {
        ...state,
        patientVisits: [...state.patientVisits, action.payload],
      };
    }
    case CONFIRM_PATIENT_REVISIT: {
      return {
        ...state,
        patientReVisitFlag: action.payload,
      };
    }
    case PATIENT_REVISIT_RECORD: {
      return {
        ...state,
        patientReVisitRecord: action.payload,
      };
    }
    case SET_PATIENT_CATEGORIES: {
      return {
        ...state,
        patientCategories: action.payload,
      };
    }
    case CLEAR_PTNT_REVISIT_RECORD: {
      return {
        ...state,
        patientReVisitRecord: {},
      };
    }
    case EDIT_PATIENT_RECORD: {
      return {
        ...state,
        editPatientRecord: action.payload,
      };
    }
    case CLEAR_EDIT_PTNT_RECORD: {
      return {
        ...state,
        editPatientRecord: {},
      };
    }
    case PATIENT_SEARCH_RECORDS: {
      return {
        ...state,
        patientSearchRecords: action.payload,
      };
    }
    case SEARCH_PATIENT: {
      const { payload: searchFor } = action;

      return {
        ...state,
        patientSearchQuery: searchFor,
      };
    }
    case UPDATE_PATIENT_VISIT: {
      var updatedRecord = action.payload;
      var mr = Number(updatedRecord.mr);
      var { patientVisits } = state;
      var filteredResults;

      filteredResults = patientVisits.filter(
        (record) => Number(record.mr) !== mr
      );
      filteredResults.push(updatedRecord);

      return {
        ...state,
        patientVisits: filteredResults,
        patientReVisitMr: "",
        patientReVisitRecord: null,
      };
    }
    default:
      return state;
  }
};

export default PatientVisits;
