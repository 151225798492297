import {
  SET_VITALS_TEMPLATE,
  SET_ASSIGN_MAIN_GROUP,
  SET_ASSIGN_MAIN_HEADING,
  SET_ASSIGN_HEADING,
  SET_ASSIGN_ELEMENT,
  SET_VITALS_TEMPLATE_IS_LOADING,
} from "../../../constants/ActionTypes";

export function setVitalsTemplate(values) {
  return (dispatch) => {
    dispatch({
      type: SET_VITALS_TEMPLATE,
      payload: values,
    });
  };
}

export function setVitalsTemplateLoading(values) {
  return (dispatch) => {
    dispatch({
      type: SET_VITALS_TEMPLATE_IS_LOADING,
      payload: values,
    });
  };
}

export function setAssignMainGroup(values) {
  return (dispatch) => {
    dispatch({
      type: SET_ASSIGN_MAIN_GROUP,
      payload: values,
    });
  };
}

export function setAssignMainHeading(values) {
  return (dispatch) => {
    dispatch({
      type: SET_ASSIGN_MAIN_HEADING,
      payload: values,
    });
  };
}

export function setAssignHeading(values) {
  return (dispatch) => {
    dispatch({
      type: SET_ASSIGN_HEADING,
      payload: values,
    });
  };
}

export function setAssignElement(values) {
  return (dispatch) => {
    dispatch({
      type: SET_ASSIGN_ELEMENT,
      payload: values,
    });
  };
}
