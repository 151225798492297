import {
    SET_LAB_VISIT_HISTORY,
    SET_RAD_VISIT_HISTORY,
  } from "../../constants/ActionTypes";
  
  const INIT_STATE = {
    setLabVisitHistory: [],
    setRadVisitHistory: [],
  };
  
  const InvestVisit = (state = INIT_STATE, action) => {
    switch (action.type) {
      case SET_LAB_VISIT_HISTORY: {
        return {
          ...state,
          setLabVisitHistory: action.payload,
        };
      }
      case SET_RAD_VISIT_HISTORY: {
        return {
          ...state,
          setRadVisitHistory: action.payload,
        };
      }
      default:
        return state;
    }
  };
  
  export default InvestVisit;