import { SET_SUB_DEPARTMENT_DATA, EDIT_SUB_DEPARTMENT_FLAG, EDIT_SUB_DEPARTMENT_RECORD, SAVE_EDITED_SUB_DEPARTMENT_RECORD } from '../../constants/ActionTypes';

const INIT_STATE = {
    subDepartmentRecords: [],
    editFlag:false,
    editRecord:null,
};


const AddSubDepartment = (state = INIT_STATE, action) => {
    switch (action.type) {
      case SET_SUB_DEPARTMENT_DATA: {
        return {
          ...state,
          subDepartmentRecords: action.payload,
        }
      };
      case EDIT_SUB_DEPARTMENT_FLAG: {
        return {
          ...state,
          editFlag: action.payload,
        }
      };
      case EDIT_SUB_DEPARTMENT_RECORD: {
        return {
          ...state,
          editRecord: action.payload,
        }
      };
      case SAVE_EDITED_SUB_DEPARTMENT_RECORD: {
        let index = state.addForm.findIndex( x => x.sr === action.payload.sr );
        let temp = action.payload;
        action.payload = [ ...state.addForm ];
        action.payload[index] = temp;
        return {
          ...state,
          addForm: action.payload,
        }
      };
      default:
        return state;
    }
  }
  
  export default AddSubDepartment;
  