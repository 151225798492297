import { SET_EMPLOYEE_DATA, EDIT_EMPLOYEE_FLAG, EDIT_EMPLOYEE_RECORD } from '../../../constants/ActionTypes';

const INIT_STATE = {
    employeeRecord: [],
    editFlag:false,
    editRecord:null,
};


const AddEmployee = (state = INIT_STATE, action) => {
    switch (action.type) {
      case SET_EMPLOYEE_DATA: {
        return {
          ...state,
        //   employeeRecord: action.payload,
          employeeRecord: action.payload.map((employee,index)=>{
              return {
                  ...employee,
                  empName: employee.FName+ ' '+ employee.LName
              }
          }),
        }
      };
      case EDIT_EMPLOYEE_FLAG: {
        return {
          ...state,
          editFlag: action.payload,
        }
      };
      case EDIT_EMPLOYEE_RECORD: {
        return {
          ...state,
          editRecord: action.payload,
        }
      };
      default:
        return state;
    }
  }
  
export default AddEmployee;
  