const convertToLocalISODateTime = (dateTime, callback) => {
  var tzoffset = new Date(dateTime).getTimezoneOffset() * 60000;
  var localISOTime = new Date(Date.parse(dateTime) - tzoffset).toISOString();
  if (callback) callback(localISOTime);
};

function getDateOnly(dateTimeString) {
  const date = new Date(dateTimeString);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed, padStart ensures 2 digits
  const day = String(date.getDate()).padStart(2, "0"); // padStart ensures 2 digits

  return `${year}-${month}-${day}`;
}

function formatCurrency(value) {
  // Convert the value to a number (if it's a string) and format it
  const number = Number(value);
  if (isNaN(number)) {
    throw new Error(
      "Invalid input: Please enter a valid number or a string representing a number."
    );
  }

  // Format the number with commas
  return number.toLocaleString("en-US");
}

export { convertToLocalISODateTime, getDateOnly, formatCurrency };
