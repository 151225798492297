import { SET_EMPLOYEE_DATA, EDIT_EMPLOYEE_FLAG, EDIT_EMPLOYEE_RECORD } from '../../../constants/ActionTypes';

export function setEmployeeData(values) {
    return (dispatch) => {
      dispatch({
        type: SET_EMPLOYEE_DATA,
        payload: values
      });
    }
}

export function EditEmployeeFlag(values) {
    return (dispatch) => {
      dispatch({
        type: EDIT_EMPLOYEE_FLAG,
        payload: values
      });
    }
}

export function EditEmployeeRecord(values) {
    return (dispatch) => {
      dispatch({
        type: EDIT_EMPLOYEE_RECORD,
        payload: values
      });
    }
}