import { SET_SINGLE_USER_ROLE_DATA, EDIT_CREATE_LOGIN_FLAG, EDIT_CREATE_LOGIN_RECORD, SET_SINGLE_USER_ROLE_MENU_PAGE_DATA } from '../../../constants/ActionTypes';


export function setSingleUserRoleData(values) {
    return (dispatch) => {
      dispatch({
        type: SET_SINGLE_USER_ROLE_DATA,
        payload: values
      });
    }
}

export function setSingleUserRoleMenuPageData(values) {
  return (dispatch) => {
    dispatch({
      type: SET_SINGLE_USER_ROLE_MENU_PAGE_DATA,
      payload: values
    });
  }
}

// export function EditCreateLoginFlag(values) {
//   return (dispatch) => {
//     dispatch({
//       type: EDIT_CREATE_LOGIN_FLAG,
//       payload: values
//     });
//   }
// }

// export function EditCreateLoginRecord(values) {
//   return (dispatch) => {
//     dispatch({
//       type: EDIT_CREATE_LOGIN_RECORD,
//       payload: values
//     });
//   }
// }