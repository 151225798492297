import {
  ADDED_TEMPLATES,
  EDIT_TEMPLATE,
  ADDED_MG_FOR_TEMPLATE,
  EDIT_MAIN_GROUP,
  CLEAR_EDIT_MAIN_GROUP,
  ADDED_MH_FOR_TEMP_AND_MG,
  EDIT_MAIN_HEADING,
  ADDED_HEADINGS,
  EDIT_HEADING,
  ADDED_ELEMENTS,
  EDIT_ELEMENT,
  TEMPLATE_TABS,
} from "../../constants/ActionTypes";

const INIT_STATE = {
  tabs :1,
  addedTemplates: [],
  addedMGForTemplate: [],
  editTemplate: {},
  addedMHForTempAndMG: [],
  editMainGroup: {},
  editMainHeading: {},
  addedHeadings: [],
  editHeading: {},
  addedElements: [],
  editElement: {},
};

const TemplateSetup = (state = INIT_STATE, action) => {
  switch (action.type) {
    case TEMPLATE_TABS: {
      return {
        ...state,
        tabs: action.payload,
      };
    }
    case ADDED_TEMPLATES: {
      return {
        ...state,
        addedTemplates: action.payload,
      };
    }
    case EDIT_TEMPLATE: {
      return {
        ...state,
        editTemplate: action.payload,
      };
    }
    case ADDED_MG_FOR_TEMPLATE: {
      return {
        ...state,
        addedMGForTemplate: action.payload,
      };
    }
    case EDIT_MAIN_GROUP: {
      return {
        ...state,
        editMainGroup: action.payload,
      };
    }
    case CLEAR_EDIT_MAIN_GROUP: {
      return {
        ...state,
        editMainGroup: {},
      };
    }
    case ADDED_MH_FOR_TEMP_AND_MG: {
      return {
        ...state,
        addedMHForTempAndMG: action.payload,
      };
    }
    case EDIT_MAIN_HEADING: {
      return {
        ...state,
        editMainHeading: action.payload,
      };
    }
    case ADDED_HEADINGS: {
      return {
        ...state,
        addedHeadings: action.payload,
      };
    }
    case EDIT_HEADING: {
      return {
        ...state,
        editHeading: action.payload,
      };
    }
    case ADDED_ELEMENTS: {
      return {
        ...state,
        addedElements: action.payload,
      };
    }
    case EDIT_ELEMENT: {
      return {
        ...state,
        editElement: action.payload,
      };
    }
    default:
      return state;
  }
};

export default TemplateSetup;
