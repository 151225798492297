import {
  SET_WAITING_PATIENT,
  SET_ATTENDED_PATIENT,
  SET_REFERRAL_PATIENT,
  SET_ADMITTED_PATIENT,
  SET_OBSERVATION_PATIENT,
  SET_CURRENT_PATIENT_TYPE,
  SET_TOTAL_WAITING_PATIENT,
  SET_TOTAL_ATTENDED_PATIENT,
  SET_TOTAL_REFERRAL_PATIENT,
  SET_TOTAL_ADMITTED_PATIENT,
  SET_TOTAL_OBSERVATION_PATIENT,
  SET_PATIENT_MR_AND_VISITID,
} from "../../constants/ActionTypes";
import { httpClient } from "../../util/Api";
import { setPatientDemographicData } from "./PatientHistory";

export function setCurrentPatientType(values) {
  return (dispatch) =>
    dispatch({ type: SET_CURRENT_PATIENT_TYPE, payload: values });
}
export function setWaitingPatient(values) {
  return (dispatch) => dispatch({ type: SET_WAITING_PATIENT, payload: values });
}
export function setAttendedPatient(values) {
  return (dispatch) =>
    dispatch({ type: SET_ATTENDED_PATIENT, payload: values });
}
export function setReferralPatient(values) {
  return (dispatch) =>
    dispatch({ type: SET_REFERRAL_PATIENT, payload: values });
}
export function setAdmittedPatient(values) {
  return (dispatch) =>
    dispatch({ type: SET_ADMITTED_PATIENT, payload: values });
}
export function setObservationPatient(values) {
  return (dispatch) =>
    dispatch({ type: SET_OBSERVATION_PATIENT, payload: values });
}

export function setTotalWaitingPatient(values) {
  return (dispatch) =>
    dispatch({ type: SET_TOTAL_WAITING_PATIENT, payload: values });
}
export function setTotalAttendedPatient(values) {
  return (dispatch) =>
    dispatch({ type: SET_TOTAL_ATTENDED_PATIENT, payload: values });
}
export function setTotalReferralPatient(values) {
  return (dispatch) =>
    dispatch({ type: SET_TOTAL_REFERRAL_PATIENT, payload: values });
}
export function setTotalAdmittedPatient(values) {
  return (dispatch) =>
    dispatch({ type: SET_TOTAL_ADMITTED_PATIENT, payload: values });
}
export function setTotalObservationPatient(values) {
  return (dispatch) =>
    dispatch({ type: SET_TOTAL_OBSERVATION_PATIENT, payload: values });
}
export function setPatientDetails(values) {
  return (dispatch) =>
    dispatch({ type: SET_PATIENT_MR_AND_VISITID, payload: values });
}

export const getPatientDemographicData = (MrNumber, VisitID, callbackFun) => {
  return async function (dispatch) {
    return await httpClient
      .get(
        `PatientMenu/PatientDemographic/GetDemographicOPD/${MrNumber},${VisitID}`
      )
      .then((data) => {
        // if (data["StatusCode"] == 200 && callbackFun) {
        dispatch(setPatientDemographicData(data.data.Value.table[0]));
        // callbackFun(data["Value"]["table"][0]);
        // }
      })
      .catch((error) => console.log(error));
  };
};
