import {
  ADD_DIAG_SESSION_DATA,
  CLEAR_DIAG_SESSION_DATA,
  REMOVE_DIAG_FROM_SESSION,
  ADDED_VISIT_PATIENT_DIAGS,
} from "../../constants/ActionTypes";

const INIT_STATE = {
  diagFormData: [],
  sessionData: [],
  addedVisitPtntDiags: [],
};

const PatientDiag = (state = INIT_STATE, action) => {
  switch (action.type) {
    case ADD_DIAG_SESSION_DATA: {
      return {
        ...state,
        diagFormData: [...state.diagFormData, action.payload],
      };
    }
    case CLEAR_DIAG_SESSION_DATA: {
      return {
        ...state,
        diagFormData: [],
      };
    }
    case REMOVE_DIAG_FROM_SESSION: {
      const icdCode = action.payload;

      const newDiagFormData = state.diagFormData.filter(
        (data) => data.icdCode !== icdCode
      );

      return {
        ...state,
        diagFormData: newDiagFormData,
      };
    }
    case ADDED_VISIT_PATIENT_DIAGS: {
      return {
        ...state,
        addedVisitPtntDiags: action.payload,
      };
    }

    default:
      return state;
  }
};

export default PatientDiag;
