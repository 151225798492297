import {
  SET_VITALS_TEMPLATE,
  SET_ASSIGN_MAIN_GROUP,
  SET_ASSIGN_MAIN_HEADING,
  SET_ASSIGN_HEADING,
  SET_ASSIGN_ELEMENT,
  SET_VITALS_TEMPLATE_IS_LOADING,
} from "../../../constants/ActionTypes";

const INIT_STATE = {
  vitalsTemplate: {},
  assignMainGroup: [],
  assignMainHeading: {},
  assignHeading: [],
  assignElements: [],
  vitalTemplateLoading: false,
  //   editFlag: false,
  //   editRecord: null,
};

const PatientEMR = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_VITALS_TEMPLATE: {
      return {
        ...state,
        vitalsTemplate: action.payload,
      };
    }

    case SET_VITALS_TEMPLATE_IS_LOADING: {
      return {
        ...state,
        vitalTemplateLoading: action.payload,
      };
    }

    case SET_ASSIGN_MAIN_GROUP: {
      return {
        ...state,
        assignMainGroup: action.payload,
      };
    }

    case SET_ASSIGN_MAIN_HEADING: {
      return {
        ...state,
        assignMainHeading: action.payload,
      };
    }

    case SET_ASSIGN_HEADING: {
      return {
        ...state,
        assignHeading: action.payload,
      };
    }

    case SET_ASSIGN_ELEMENT: {
      return {
        ...state,
        assignElements: action.payload,
      };
    }

    default:
      return state;
  }
};

export default PatientEMR;
