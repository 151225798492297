import { SWITCH_ADD_HOSPITAL_PAGE, SET_GROUPS_RECORD, EDIT_GROUP_FLAG, EDIT_GROUP_RECORD, SAVE_EDITED_GROUP_RECORD } from '../../constants/ActionTypes';

const INIT_STATE = {
    pageId: 1,
    groupsRecord: [],
    editGroupFlag:false,
    editGroupRecord:null,
};

const AddGroup = (state = INIT_STATE, action) => {
    switch (action.type) {
      case SWITCH_ADD_HOSPITAL_PAGE: {
        return {
          ...state,
          pageId: action.payload,
        }
      };
      case SET_GROUPS_RECORD: {
        return {
          ...state,
          groupsRecord: action.payload,
        }
      };
      case EDIT_GROUP_FLAG: {
        return {
          ...state,
          editGroupFlag: action.payload,
        }
      };
      case EDIT_GROUP_RECORD: {
        return {
          ...state,
          editGroupRecord: action.payload,
        }
      };
      case SAVE_EDITED_GROUP_RECORD: {
        let index = state.addForm.findIndex(x => x.sr === action.payload.sr);
        let temp = action.payload;
        action.payload = [...state.addForm];
        action.payload[index] = temp;
        return {
          ...state,
          addForm: action.payload,
        }
      };
      default:
        return state;
    }
  }
  
  export default AddGroup;
  