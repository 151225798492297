import React, { useEffect, useState } from "react";
import { Route, Switch } from "react-router-dom";
import { useGroups } from "../util/api/useGroups";
import { useHospitals } from "../util/api/useHospitals";
import { useMenuPage } from "../util/api/useMenuPage";
import { useEmployee } from "../util/api/administration/useEmployee";
import { useRole } from "../util/api/administration/useRole";
import { useDepartments } from "../util/api/useDepartment";
import { useServiceMainGroup } from "../util/api/billing/useServiceMainGroup";
import { useServiceRateSetup } from "../util/api/billing/useServiceRateSetup";
import { useCommon } from "../util/api/useCommon";
import { usePanel } from "../util/api/panel&package/usePanel";
import { usePackage } from "../util/api/panel&package/usePackage";
import { useDispatch, useSelector } from "react-redux";
import { useAdmitAndObservation } from "../util/api/useAdmitAndObservation";
import {
  setGroupsData,
  setHospitalsData,
  setMenuData,
  setEmployeeData,
  setDepartmentsData,
  setCountriesData,
  setPanelsData,
  setPackagesTypeData,
  setLateralityData,
  setRateTypesData,
  setWardsData,
  setObservationRoomsData,
  setPatientCategories,
  setCitiesData,
  setAssignedTemplates,
  setAssignedMainGroup,
} from "../appRedux/actions";
import AppNotificationContainer from "../components/AppNotificationContainer";
import { setRoleData } from "../appRedux/actions/administration/AddRole";
import { setServiceMainGroupData } from "../appRedux/actions";
import { usePatient } from "../util/api/usePatient";
import { useTemplateRetrieval } from "../util/api/useTemplateRetrieval";
import asyncComponent from "util/asyncComponent";
import CircularProgress from "../components/CircularProgress";
import { importComponentURL } from "../util/utility-methods";
import { useHospitalName } from "../util/hooks/useHospitalName";

let mainGroupData = [];

const App = ({ match }) => {
  const User = useSelector(({ common }) => common.authUser.User);
  const hospitalID = User.HospitalID;
  const menuInfo = User.MenuInfo;
  const [messages, setMessages] = useState("");
  const [errors, setErrors] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [applicationLoading, setApplicationLoading] = useState(true);
  const [routes, setRoutes] = useState([]);
  const { currentHospital, currentUser } = useHospitalName();

  const dispatch = useDispatch();

  const { getGroups } = useGroups();
  const { getHospitals } = useHospitals();
  const { getMainMenu } = useMenuPage();
  const { getRole } = useRole();
  const { getServiceMainGroup } = useServiceMainGroup();
  const { getDepartmentsByHospitalId } = useDepartments();
  const { getCountries } = useCommon();
  const { getEmployee } = useEmployee();
  const { getPanels } = usePanel();
  const { getPackageType } = usePackage();
  const { getWardNature, getObservationRooms } = useAdmitAndObservation();
  const { getServicesLateralityList, getServicesRateType } =
    useServiceRateSetup();
  const { getPtntCategories, getCitiesByCountryId } = usePatient();
  const { getAssignedTemplates, getAssignedMainGroup } = useTemplateRetrieval();

  const {
    error: global_error,
    loading: global_loading,
    message: global_message,
  } = useSelector(({ common }) => common);

  useEffect(() => {
    let unmounted = false;

    if (unmounted === false) {
      setIsLoading(global_loading);
      setMessages(global_message);
      setErrors(global_error);
    }
    return () => {
      unmounted = true;
    };
  }, [global_error, global_loading, global_message]);

  /*To Limit Renders on Individual Pages
  we are calling all the api's here and then dispatching the data to the redux store*/

  useEffect(() => {
    let unmounted = false;
    let firstResponseLogged = false;
    if (unmounted === false) {
      Promise.all([
        new Promise((resolve, reject) =>
          getAssignedTemplates((data) => {
            dispatch(setAssignedTemplates(data));
            if (data.length === 0) {
              resolve();
            }

            data.map((it) => {
              getAssignedMainGroup(
                it.EmrTemplateID,
                function getAssignedMainGroupCb(maindata) {
                  mainGroupData.push(maindata);
                  if (data.length === mainGroupData.length) {
                    dispatch(setAssignedMainGroup(mainGroupData));
                    mainGroupData = [];
                    resolve();
                  }
                }
              );
            });
          })
        ),
        // new Promise((resolve, reject) =>
        //   getObservationRooms((data) => {
        //     dispatch(setObservationRoomsData(data));
        //     resolve();
        //   })
        // ),
        new Promise((resolve, reject) =>
          getPtntCategories((data) => {
            dispatch(setPatientCategories(data));
            resolve();
          })
        ),
        // new Promise((resolve, reject) =>
        //   getAdmitWards(hospitalID, (data) => {
        //     dispatch(setWardsData(data));
        //     resolve();
        //   })
        // ),
        new Promise((resolve, reject) =>
          getGroups((data) => {
            dispatch(setGroupsData(data));
            resolve();
          })
        ),
        new Promise((resolve, reject) =>
          getCountries((data) => {
            dispatch(setCountriesData(data));
            resolve();
          })
        ),
        new Promise((resolve, reject) =>
          getServiceMainGroup((data) => {
            dispatch(setServiceMainGroupData(data));
            resolve();
          })
        ),
        // new Promise((resolve, reject) =>
        //   getRole((data) => {
        //     dispatch(setRoleData(data));
        //     resolve();
        //   })4
        // ),

        new Promise((resolve, reject) =>
          getRole((data) => {
            if (Array.isArray(data)) {
              let rolesRecord;

              if (currentUser.IsSuperAdmin === false) {
                rolesRecord = data.filter((record) => !record.IsSuperAdmin);
              } else {
                rolesRecord = data;
              }
              dispatch(setRoleData(rolesRecord));
              resolve();
            } else {
              reject(new Error("Data is not an array"));
            }
          })
        ),

        new Promise((resolve, reject) =>
          getMainMenu((data) => {
            dispatch(setMenuData(data));
            resolve();
          })
        ),
        new Promise((resolve, reject) =>
          getHospitals((data) => {
            dispatch(setHospitalsData(data));
            resolve();
          })
        ),
        new Promise((resolve, reject) =>
          getEmployee((data) => {
            dispatch(setEmployeeData(data));
            resolve();
          })
        ),
        new Promise((resolve, reject) =>
          getDepartmentsByHospitalId(hospitalID, (data) => {
            dispatch(setDepartmentsData(data));
            resolve();
          })
        ),
        new Promise((resolve, reject) =>
          getPanels((data) => {
            dispatch(setPanelsData(data));
            resolve();
          })
        ),
        new Promise((resolve, reject) =>
          getPackageType((data) => {
            dispatch(setPackagesTypeData(data));
            resolve();
          })
        ),
        new Promise((resolve, reject) =>
          getServicesLateralityList((data) => {
            dispatch(setLateralityData(data));
            resolve();
          })
        ),
        new Promise((resolve, reject) =>
          getServicesRateType((data) => {
            dispatch(setRateTypesData(data));
            resolve();
          })
        ),
        new Promise((resolve, reject) =>
          getCitiesByCountryId(1, (data) => {
            dispatch(setCitiesData(data));
            resolve();
          })
        ),
      ]).then(() => {
        setApplicationLoading(false);
      });
    }
    return () => {
      unmounted = true;
    };
  }, []);

  useEffect(() => {
    const loadRoutes = async () => {
      const loadedRoutes = await Promise.all(
        menuInfo.map(async (menu) => {
          const ComponentFileURL = importComponentURL(menu.PageURL);
          const Component = await asyncComponent(() =>
            import(`${ComponentFileURL}`).catch((error) => {
              console.error("Error loading the Component: ", error);
              return null;
            })
          );
          return {
            path: `${match.url}${menu.PageURL.substring(1)}`,
            component: (props) => (
              <Component
                {...props}
                InsertState={menu.InsertState}
                CloseState={menu.CloseState}
                ReadState={menu.ReadState}
                UpdateState={menu.UpdateState}
                ApproveState={menu.ApproveState}
                DeleteState={menu.DeleteState}
              />
            ),
          };
        })
      );
      setRoutes(loadedRoutes);
    };

    loadRoutes();
  }, [match.url]);

  if (applicationLoading) return <CircularProgress />;
  if (routes.length === 0) return <CircularProgress />;

  return (
    <>
      <div>
        <AppNotificationContainer
          loading={isLoading}
          error={errors}
          message={messages}
        />
      </div>

      <div className="gx-main-content-wrapper">
        <Switch>
          {routes.map((route, index) => (
            <Route key={index} path={route.path} render={route.component} />
          ))}
          <Route
            key={routes.length}
            path={`${match.url}radiology`}
            component={asyncComponent(() =>
              import("./RadiologyPage")
            )}
          />
          <Route
            key={routes.length + 1}
            path={`${match.url}*`}
            component={asyncComponent(() =>
              import("../components/AccessDenied")
            )}
          />
        </Switch>
      </div>
    </>
  );
};

export default App;
