import { applyMiddleware, createStore } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import storage from 'redux-persist/lib/storage';
import { routerMiddleware } from "connected-react-router";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";
import createRootReducer from "../reducers";
import logger from "redux-logger";

const createHashHistory = require("history").createHashHistory;
export const history = createHashHistory();
const routeMiddleware = routerMiddleware(history);
const middlewares = [thunk, logger, routeMiddleware];
// console.log = console.warn = console.error = () => {};

const persistConfig = {
  key: 'root',
  storage,
}

const persistedReducer = persistReducer(persistConfig, createRootReducer(history))

export default function configureStore(preloadedState = {}) {
  let store = createStore(
    persistedReducer, // root reducer with router state
    preloadedState,
    composeWithDevTools(
      applyMiddleware(
        routerMiddleware(history), // for dispatching history actions
        ...middlewares
      )
    )
  );
  let persistor = persistStore(store)
  return { store, persistor }
}
