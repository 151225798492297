import { SET_ASSIGNED_MODULES_TO_ROLES_DATA, EDIT_ASSIGNED_MODULES_TO_ROLES_FLAG, EDIT_ASSIGNED_MODULES_TO_ROLES_RECORD, LOAD_ASSIGNED_MODULES_TO_ROLES_RECORD, LOAD_ASSIGNED_MODULES_TO_ROLES_FLAG } from '../../../constants/ActionTypes';

const INIT_STATE = {
    assignModulesToRoleData: [],
    editFlag:false,
    editRecord:null,
    loadAssignedModuleToRolesRecord:[],
    loadAssignedModuleToRolesFlag: false,
};


const AssignModulesToRole = (state = INIT_STATE, action) => {
    switch (action.type) {
      case SET_ASSIGNED_MODULES_TO_ROLES_DATA: {
        let sr = 0;
        return {
          ...state,
          assignModulesToRoleData: action.payload.length > 0 ? action.payload.map((item, index) =>{
            sr+=1; 
            return {...item, sr}
          }): action.payload,
        }
      };
      case EDIT_ASSIGNED_MODULES_TO_ROLES_FLAG: {
        return {
          ...state,
          editFlag: action.payload,
        }
      };
      case EDIT_ASSIGNED_MODULES_TO_ROLES_RECORD: {
        return {
          ...state,
          editRecord: action.payload,
        }
      };
      case LOAD_ASSIGNED_MODULES_TO_ROLES_RECORD: {
        return {
          ...state,
          loadAssignedModuleToRolesRecord: [action.payload],
        }
      };
      case LOAD_ASSIGNED_MODULES_TO_ROLES_FLAG: {
        return {
          ...state,
          loadAssignedModuleToRolesFlag: action.payload,
        }
      };
      default:
        return state;
    }
  }
  
export default AssignModulesToRole;
  