import { useEffect, useState } from "react";
import { httpClient, httpClient3 } from "../../../util/Api";
import { useDispatch, useSelector } from "react-redux";
import jwt_decode from "jwt-decode";
import { setAuthenticUser } from "../../../appRedux/actions/Common";
import _ from "lodash";
import { message as toastNotification } from "antd";

export const unAuthUser = "User Not Verified";

export const isAuthoriedUser = (user) => user.User.Username !== unAuthUser;

export const useProvideAuth = () => {
  const user = JSON.parse(localStorage.getItem("authUser"));
  const [authUser, setAuthUser] = useState(
    !_.isEmpty(user) && isAuthoriedUser(user) ? user : null
  );
  const [error, setError] = useState("");
  const [isLoadingUser, setLoadingUser] = useState(true);
  const [isLoading, setLoading] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    setLoadingUser(false);
    if (authUser) {
      setIsLoggedIn(true);
      dispatch(setAuthenticUser(authUser));
      localStorage.setItem("token", authUser.token);
      localStorage.setItem("user", JSON.stringify(jwt_decode(authUser.token)));
      localStorage.setItem("authUser", JSON.stringify(authUser));
      httpClient.defaults.headers.common["Authorization"] =
        "Bearer " + authUser.token;
      httpClient.defaults.withCredentials = true;
      httpClient3.defaults.headers.common["Authorization"] =
        "Bearer " + authUser.token;
      httpClient3.defaults.withCredentials = true;
    }
  }, [authUser]);

  const fetchStart = () => {
    setLoading(true);
    setError("");
  };

  const fetchSuccess = () => {
    setLoading(false);
    setError("");
  };

  const fetchError = (error) => {
    setLoading(false);
    setError(error);
  };

  const userLogin = (user, callbackFun) => {
    fetchStart();
    httpClient
      .get(`Login?username=${user.userName}&password=${user.password}`, {
        withCredentials: true,
      })
      .then(({ data }) => {
        fetchSuccess();
        if (isAuthoriedUser(data)) {
          setAuthUser(data);
          localStorage.setItem("authUser", JSON.stringify(data));
          dispatch(setAuthenticUser(data));
        } else {
          toastNotification.error("Username or Password is invalid");
        }
        if (callbackFun) callbackFun();
      })
      .catch(function (error) {
        fetchError(error.message);
      });
  };

  const userSignup = (user, callbackFun) => {
    fetchStart();
    httpClient
      .post("auth/register", user)
      .then(({ data }) => {
        if (data.result) {
          fetchSuccess();
          localStorage.setItem("token", data.token.access_token);
          httpClient.defaults.headers.common["Authorization"] =
            "Bearer " + data.token.access_token;
          getAuthUser();
          if (callbackFun) callbackFun();
        } else {
          fetchError(data.error);
        }
      })
      .catch(function (error) {
        fetchError(error.message);
      });
  };

  const sendPasswordResetEmail = (email, callbackFun) => {
    fetchStart();

    setTimeout(() => {
      fetchSuccess();
      if (callbackFun) callbackFun();
    }, 300);
  };

  const confirmPasswordReset = (code, password, callbackFun) => {
    fetchStart();

    setTimeout(() => {
      fetchSuccess();
      if (callbackFun) callbackFun();
    }, 300);
  };

  const renderSocialMediaLogin = () => null;

  const userSignOut = (callbackFun) => {
    fetchStart();
    localStorage.removeItem("token");
    fetchSuccess();
    if (callbackFun) callbackFun();
  };

  const getAuthUser = () => {
    fetchStart();
    httpClient
      .get(`Customer?customerId=${localStorage.getItem("id")}`)
      .then(({ data }) => {
        if (data.HasErrors === false) {
          fetchSuccess();
          setAuthUser(data);
          fetchError(data);
        }
      })
      .catch(function (error) {
        httpClient.defaults.headers.common["Authorization"] = "";
        fetchError(error.message);
      });
  };

  const getLogout = () => {
    fetchStart();
    httpClient
      .get(`Logout`)
      .then(({ data }) => {
        if (data === "Session Is Cleared") {
          fetchSuccess();
          setIsLoggedIn(false);
          setAuthUser(null);
          dispatch(setAuthenticUser(null));
          localStorage.removeItem("token");
          localStorage.removeItem("user");
          localStorage.removeItem("authUser");
          httpClient.defaults.headers.common["Authorization"] = "";
        } else {
          fetchError(data);
        }
      })
      .catch(function (error) {
        setIsLoggedIn(false);
        setAuthUser(null);
        dispatch(setAuthenticUser(null));
        localStorage.removeItem("token");
        localStorage.removeItem("user");
        localStorage.removeItem("authUser");
        httpClient.defaults.headers.common["Authorization"] = "";
      });
  };

  // Subscribe to user on mount
  // Because this sets state in the callback it will cause any ...
  // ... component that utilizes this hook to re-render with the ...
  // ... latest auth object.

  // Return the user object and auth methods
  return {
    isLoadingUser,
    isLoading,
    authUser,
    isLoggedIn,
    error,
    setError,
    setAuthUser,
    getAuthUser,
    userLogin,
    userSignup,
    userSignOut,
    renderSocialMediaLogin,
    sendPasswordResetEmail,
    confirmPasswordReset,
    getLogout,
  };
};
