import { SET_SERVICE_MAIN_GROUP_DATA} from '../../../constants/ActionTypes';


export function setServiceMainGroupData(values) {
    return (dispatch) => {
      dispatch({
        type: SET_SERVICE_MAIN_GROUP_DATA,
        payload: values
      });
    }
}

