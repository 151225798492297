import { httpClient } from "../../Api";
import { useAuth } from "../../../authentication";
import { postApi, putApi, getApi } from "../../Axios";

export const usePanel = () => {
  const { getLogout } = useAuth();

  const addPanel = (panel, callbackFun) => {
    postApi(
      `Administration/ManagePackage/SaveSponser`,
      panel,
      callbackFun,
      "msg",
      getLogout,
      true
    );
  };

  const getPanels = (callbackFun) => {
    getApi(
      `Administration/ManagePackage/GetSponser`,
      callbackFun,
      "data",
      getLogout,
      false
    );
  };

  const updatePanel = (model, callbackFun) => {
    putApi(
      `Administration/ManagePackage/UpdateSponser`,
      model,
      callbackFun,
      "msg",
      getLogout,
      true
    );
  };

  return {
    addPanel,
    getPanels,
    updatePanel,
  };
};
