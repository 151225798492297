import React from "react";
import { Avatar, Popover } from "antd";
import { useAuth } from "../../authentication";
import { useHistory } from "react-router-dom";
import "./custom-avatar-style.css";
import { ClearEditPtntRecord, setPatientDetails } from "../../appRedux/actions";
import { useDispatch, useSelector } from "react-redux";

const UserInfo = () => {
  const dispatch = useDispatch();
  const { userSignOut, getLogout, authUser } = useAuth();
  const history = useHistory();
  const onLogoutClick = () => {
    userSignOut(() => {
      console.log("logout");
      dispatch(ClearEditPtntRecord());
      localStorage.removeItem("token");
      localStorage.removeItem("user");
      dispatch(
        setPatientDetails({
          MrNumber: undefined,
          VisitID: undefined,
        })
      );
      getLogout();
      history.push("/signin");
    });
  };

  const userMenuOptions = (
    <ul className="gx-user-popover">
      {/*<li>My Account</li>
  <li>Connections</li>*/}
      <li onClick={onLogoutClick}>Logout</li>
    </ul>
  );

  return (
    <Popover
      overlayClassName="gx-popover-horizantal"
      placement="bottomRight"
      content={userMenuOptions}
      trigger="click"
    >
      <Avatar
        src={authUser.User.logo}
        className="gx-avatar gx-pointer fixed-height"
        alt=""
      />
    </Popover>
  );
};

export default UserInfo;
