import {
SET_PATIENT_DEMOGRAPHIC_DATA,     
SET_PATIENT_DIAGNOSIS_HISTORY,  
SET_PATIENT_INVESTIGATION_LAB_HISTORY,
SET_PATIENT_INVESTIGATION_RAD_HISTORY,
SET_PATIENT_MEDICATION_HISTORY, 
SET_PATIENT_ERM_HISTORY, 
SET_PATIENT_PROCEDURE_HISTORY,
SET_PATIENT_OUTCOME_HISTORY, 
SET_PATIENT_ADMISSION_HISTORY,
SET_PATIENT_SEARCH_HISTORY,
  } from "../../constants/ActionTypes";
  
  import ChangeDateFormatFromAPIToLocal from "../../util/ChangeDateFormatFromAPIToLocal";

  export function setPatientDemographicData(values) {
    return (dispatch) => dispatch({ type: SET_PATIENT_DEMOGRAPHIC_DATA, payload: values });
  }
  

  export function setPatientDiagnosisHistory(values) {
    return (dispatch) => dispatch({ type: SET_PATIENT_DIAGNOSIS_HISTORY, payload: values });
  }

  export function setPatientInvestiagtionLabHistory(values) {
    return (dispatch) => dispatch({ type: SET_PATIENT_INVESTIGATION_LAB_HISTORY, payload: values });
  }

  export function setPatientInvestiagtionRadHistory(values) {
    return (dispatch) => dispatch({ type: SET_PATIENT_INVESTIGATION_RAD_HISTORY, payload: values });
  }

  export function setPatientMedicationHistory(values) {
    return (dispatch) => dispatch({ type: SET_PATIENT_MEDICATION_HISTORY, payload: values });
  }

  export function setPatientERMHistory(values) {
    return (dispatch) => dispatch({ type: SET_PATIENT_ERM_HISTORY, payload: values });
  }

  export function setPatientProcedureHistory(values) {
    return (dispatch) => dispatch({ type: SET_PATIENT_PROCEDURE_HISTORY, payload: values });
  }

  export function setPatientOutcomeHistory(values) {
    return (dispatch) => dispatch({ type: SET_PATIENT_OUTCOME_HISTORY, payload: values });
  }

  export function setPatientAdmissionHistory(values) {
    return (dispatch) => dispatch({ type: SET_PATIENT_ADMISSION_HISTORY, payload: values });
  }

  export function setPatientSearchedHistory(values) {
    // let value = values
    // value.map((VISITDATE)=>
    //   VISITDATE = ChangeDateFormatFromAPIToLocal(VISITDATE)
    // )
    // console.log("Actions Searched History",value)
    return (dispatch) => dispatch({ type: SET_PATIENT_SEARCH_HISTORY, payload: values });
  }
