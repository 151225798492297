import {
    SET_PATIENT_TOKEN,
    SET_PATIENT_VISIT
  } from "../../constants/ActionTypes";
  


  export function setPatientVisitTokenData(values) {
    return (dispatch) => dispatch({ type: SET_PATIENT_TOKEN, payload: values });
  }

  export function setPatientVisitIDData(values) {
    return (dispatch) => dispatch({ type: SET_PATIENT_VISIT, payload: values });
  }