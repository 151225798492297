import { INCREMENT_UNIQUE_COUNTER, SET_UNIQUE_COUNTER } from "../../constants/ActionTypes";

const INIT_STATE = {
  uniqueCounter: 0
}

const Procurement = (state = INIT_STATE,action) => {
  switch(action.type){
    case SET_UNIQUE_COUNTER: {
      return {
        ...state,
        uniqueCounter: action.payload
      }
    }
    case INCREMENT_UNIQUE_COUNTER:{
      return {
        ...state,
        uniqueCounter: state.uniqueCounter + 1
      }
    }
    default: return state;
  }
}

export default Procurement