import {
  SET_ASSIGN_TEMPLATE,
  SET_ASSIGN_MAIN_GROUP_TEMPLATE_RETRIEVAL,
  SET_TEMPLATES_TABLE_DATA,
  LOGOUT,
  SET_TEMPLATES_TABLE_EDIT_DATA,
  CLEAR_TEMPLATES_TABLE_STATE,
  SET_TEMPLATES_TABLE_RECORD,
  SET_SELECTED_TEMPLATE_ID,
  SET_SELECTED_MAIN_GROUP_ID,
  SET_SELECTED_MAIN_HEADING_ID,
  SET_SELECTED_HEADING_ID,
  SET_MAIN_HEADING_DATA,
  SET_HEADING_DATA,
} from "../../constants/ActionTypes";

const INIT_STATE = {
  assignedTemplates: [],
  assignedMainGroup: [],
  assignedMainHeading: [],
  assignedHeading: [],
  templateRetrievalState: {
    templatesTableData: [],
    templatesTableEditData: [],
  },
  templateId: "",
  mainGroupId: "",
  mainHeadingId: "",
  headingId: "",
};

const TemplateRetrieval = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_ASSIGN_TEMPLATE: {
      return {
        ...state,
        assignedTemplates: action.payload,
      };
    }
    case SET_TEMPLATES_TABLE_DATA: {
      return {
        ...state,
        templateRetrievalState: {
          templatesTableData: [
            ...state.templateRetrievalState.templatesTableData,
            action.payload,
          ],
        },
      };
    }
    case SET_TEMPLATES_TABLE_EDIT_DATA: {
      return {
        ...state,
        templateRetrievalState: {
          templatesTableEditData: [
            ...state.templateRetrievalState.templatesTableEditData,
            action.payload,
          ],
        },
      };
    }
    case CLEAR_TEMPLATES_TABLE_STATE: {
      return {
        ...state,
        templateRetrievalState: {
          templatesTableData: [],
          templatesTableEditData: [],
        },
      };
    }
    case SET_ASSIGN_MAIN_GROUP_TEMPLATE_RETRIEVAL: {
      return {
        ...state,
        assignedMainGroup: action.payload,
      };
    }
    case SET_TEMPLATES_TABLE_RECORD: {
      return {
        ...state,
        templateRetrievalState: {
          templatesTableData: action.payload,
        },
      };
    }
    case SET_SELECTED_TEMPLATE_ID: {
      return {
        ...state,
        templateId: action.payload,
      };
    }
    case SET_SELECTED_MAIN_GROUP_ID: {
      return {
        ...state,
        mainGroupId: action.payload,
      };
    }
    case SET_SELECTED_MAIN_HEADING_ID: {
      return {
        ...state,
        mainHeadingId: action.payload,
      };
    }
    case SET_SELECTED_HEADING_ID: {
      return {
        ...state,
        headingId: action.payload,
      };
    }
    case SET_MAIN_HEADING_DATA: {
      return {
        ...state,
        assignedMainHeading: action.payload,
      };
    }
    case SET_HEADING_DATA: {
      return {
        ...state,
        assignedHeading: action.payload,
      };
    }
    case LOGOUT:
      return INIT_STATE;
    default:
      return state;
  }
};

export default TemplateRetrieval;
