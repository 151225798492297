import {
  SET_ASSIGN_TEMPLATE,
  SET_ASSIGN_MAIN_GROUP_TEMPLATE_RETRIEVAL,
  LOGOUT,
  SET_TEMPLATES_TABLE_DATA,
  SET_TEMPLATES_TABLE_EDIT_DATA,
  CLEAR_TEMPLATES_TABLE_STATE,
  SET_TEMPLATES_TABLE_RECORD,
  SET_SELECTED_TEMPLATE_ID,
  SET_SELECTED_MAIN_GROUP_ID,
  SET_SELECTED_MAIN_HEADING_ID,
  SET_SELECTED_HEADING_ID,
  SET_MAIN_HEADING_DATA,
  SET_HEADING_DATA,
} from "../../constants/ActionTypes";

export function setAssignedTemplates(values) {
  return (dispatch) => dispatch({ type: SET_ASSIGN_TEMPLATE, payload: values });
}

export function setAssignedMainGroup(values) {
  return (dispatch) =>
    dispatch({
      type: SET_ASSIGN_MAIN_GROUP_TEMPLATE_RETRIEVAL,
      payload: values,
    });
}

export function clearTemplateRetrieval(values) {
  return (dispatch) => dispatch({ type: LOGOUT, payload: values });
}

export function setTemplateTableData(values) {
  return (dispatch) =>
    dispatch({ type: SET_TEMPLATES_TABLE_DATA, payload: values });
}

export function clearTemplateTableState() {
  return (dispatch) => dispatch({ type: CLEAR_TEMPLATES_TABLE_STATE });
}

export function setTemplateTableRecord(values) {
  return (dispatch) => dispatch({ type: SET_TEMPLATES_TABLE_RECORD, values });
}

export function setTemplateTableEditData(values) {
  return (dispatch) =>
    dispatch({ type: SET_TEMPLATES_TABLE_EDIT_DATA, payload: values });
}

export function setSelectedTemplateID(values) {
  return (dispatch) =>
    dispatch({ type: SET_SELECTED_TEMPLATE_ID, payload: values });
}

export function setSelectedMainGroupID(values) {
  return (dispatch) =>
    dispatch({ type: SET_SELECTED_MAIN_GROUP_ID, payload: values });
}

export function setSelectedMainHeadingID(values) {
  return (dispatch) =>
    dispatch({ type: SET_SELECTED_MAIN_HEADING_ID, payload: values });
}

export function setSelectedHeadingID(values) {
  return (dispatch) =>
    dispatch({ type: SET_SELECTED_HEADING_ID, payload: values });
}

export function setMainHeadingData(values) {
  return (dispatch) =>
    dispatch({ type: SET_MAIN_HEADING_DATA, payload: values });
}

export function setHeadingData(values) {
  return (dispatch) => dispatch({ type: SET_HEADING_DATA, payload: values });
}