import { SET_SUB_DEPARTMENT_DATA , EDIT_SUB_DEPARTMENT_FLAG, EDIT_SUB_DEPARTMENT_RECORD, SAVE_EDITED_SUB_DEPARTMENT_RECORD } from '../../../constants/ActionTypes';

export function setSubDepartmentData(values) {
    return (dispatch) => {
      dispatch({
        type: SET_SUB_DEPARTMENT_DATA ,
        payload: values
      });
    }
}

export function EditSubDepartmentFlag(values) {
    return (dispatch) => {
      dispatch({
        type: EDIT_SUB_DEPARTMENT_FLAG,
        payload: values
      });
    }
}

export function EditSubDepartmentRecord(values) {
    return (dispatch) => {
      dispatch({
        type: EDIT_SUB_DEPARTMENT_RECORD,
        payload: values
      });
    }
}

export function SaveEditedSubDepartmentRecord(values) {
  return (dispatch) => {
    dispatch({
      type: SAVE_EDITED_SUB_DEPARTMENT_RECORD,
      payload: values
    });
  }
}