import {
  SET_MENU_RECORD,
  EDIT_MENU_FLAG,
  EDIT_MENU_RECORD,
  SET_MENU_PAGE_RECORD,
  EDIT_PAGE_FLAG,
  EDIT_PAGE_RECORD,
  SET_SELECTED_PAGES,
} from "../../constants/ActionTypes";

const INIT_STATE = {
  menuRecord: [],
  editMenuFlag: false,
  editMenuRecord: null,
  menuPagesRecord: [],
  editMenuPageFlag: false,
  editMenuPageRecord: null,
  editPageFlag: false,
  editPageRecord: null,
  selectedPages: [],
};

const MenuAndPage = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_MENU_RECORD: {
      let extendedPayload = [];
      action.payload?.map((item, index) => {
        let extendedItem = {
          ...item,
          status: item.IsActive ? "Active" : "In-Active",
        };
        extendedPayload.push(extendedItem);
      });
      return {
        ...state,
        menuRecord: extendedPayload,
      };
    }

    case EDIT_MENU_FLAG: {
      return {
        ...state,
        editMenuFlag: action.payload,
      };
    }

    case EDIT_MENU_RECORD: {
      return {
        ...state,
        editMenuRecord: action.payload,
      };
    }

    case SET_MENU_PAGE_RECORD: {
      let extendedMenuPagesRecord = action.payload.map((item, index) => {
        return {
          ...action.payload[index],
          functions: {
            insert: item.InsertState,
            view: item.ReadState,
            delete: item.DeleteState,
            update: item.UpdateState,
          },
        };
      });
      return {
        ...state,
        menuPagesRecord: extendedMenuPagesRecord,
      };
    }

    case EDIT_PAGE_FLAG: {
      return {
        ...state,
        editPageFlag: action.payload,
      };
    }

    case EDIT_PAGE_RECORD: {
      return {
        ...state,
        editPageRecord: action.payload,
      };
    }

    case SET_SELECTED_PAGES: {
      return {
        ...state,
        selectedPages: action.payload,
      };
    }

    default:
      return state;
  }
};

export default MenuAndPage;
