import {
    BILLING_ACCESSED,
    BILLING_ACCESSED_DATA,
    BILLING_PATIENTS_DATA,
    BILLING_PATIENT_INVOICE_DATA
  } from "../../constants/ActionTypes";
  
  const INIT_STATE = {
    billingAccessed: false,
    billingAccessedData: {},
    billingPatientsData: [],
    billingPatientInvoice: {},
  };
  
  const PatientBilling = (state = INIT_STATE, action) => {
    switch (action.type) {
      case BILLING_ACCESSED: {
        return {
          ...state,
          billingAccessed: action.payload,
        };
      }

      case BILLING_ACCESSED_DATA: {
        return {
          ...state,
          billingAccessedData: action.payload,
        };
      }

      case BILLING_PATIENTS_DATA: {
        return {
          ...state,
          billingPatientsData: action.payload,
        };
      }
      case BILLING_PATIENT_INVOICE_DATA: {
        return {
          ...state,
          billingPatientInvoice: action.payload,
        };
      }
      default:
        return state;
    }
  };
  
  export default PatientBilling;