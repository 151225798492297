import {
  ADD_INVEST_SESSION_DATA,
  CLEAR_INVEST_SESSION_DATA,
  REMOVE_INVEST_FROM_SESSION,
  // ADDED_VISIT_PATIENT_DIAGS,
} from "../../constants/ActionTypes";

export function AddInvestSessionData(values) {
  return (dispatch) =>
    dispatch({ type: ADD_INVEST_SESSION_DATA, payload: values });
}

export function ClearInvestSessionData() {
  return (dispatch) => dispatch({ type: CLEAR_INVEST_SESSION_DATA });
}

export function RemoveInvestFromSession(value) {
  return (dispatch) =>
    dispatch({ type: REMOVE_INVEST_FROM_SESSION, payload: value });
}

// export function AddedVisitPtntDiags(value) {
//   return (dispatch) =>
//     dispatch({ type: ADDED_VISIT_PATIENT_DIAGS, payload: value });
// }
