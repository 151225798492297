import {SET_SERVICES_DATA, EDIT_SERVICE_FLAG, EDIT_SERVICE_RECORD} from '../../../constants/ActionTypes';

const INIT_STATE = {
    servicesData : [],
    editFlag:false,
    editRecord:null,
};

const ServicesSetup = (state = INIT_STATE, action) => {
    switch (action.type) {

      case SET_SERVICES_DATA: {
        return {
          ...state,
          servicesData: action.payload,
        }
      };

      case EDIT_SERVICE_FLAG: {
        return {
          ...state,
          editFlag: action.payload,
        }
      };

      case EDIT_SERVICE_RECORD: {
        return {
          ...state,
          editRecord: action.payload,
        }
      };

      default:
        return state;
    }
  }
  
  export default ServicesSetup;
  