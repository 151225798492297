import { useAuth } from "../../authentication";
import { getApi, postApi } from "../Axios";
import { getApi as newGetApi, postApi as newPostApi } from "../Axios_Dev";

export const useAdmitAndObservation = () => {
  const { getLogout } = useAuth();

  const getWardNature = (callbackFun) => {
    newGetApi(
      `Admission/LoadSubDepNatures`,
      callbackFun,
      "data",
      getLogout,
      false
    );
  };

  const getAdmitWards = (Type, HsptID, SubDeptNature, callbackFun) => {
    getApi(
      `DefaultMethods/DefaultMethods?Type=${Type}&HsptID=${HsptID}&SubDeptNature=${SubDeptNature}`,
      callbackFun,
      "data",
      getLogout,
      false
    );
  };

  const addAdmission = (payload, callbackFun) => {
    newPostApi(`Admission/Post`, payload, callbackFun, "msg", getLogout, true);
  };

  const getAdmitPatientHistory = (MrNumber, callbackFun) => {
    newGetApi(
      `Admission/GetAdmitPatientHistory/${MrNumber}`,
      callbackFun,
      "data",
      getLogout,
      false
    );
  };

  const getUOPatientHistory = (MrNumber, callbackFun) => {
    newGetApi(
      `Observation/GetUOPatientHistory/${MrNumber}`,
      callbackFun,
      "data",
      getLogout,
      false
    );
  };

  const getObservationRooms = (Type, HsptID, SubDeptNature, callbackFun) => {
    getApi(
      `DefaultMethods/DefaultMethods?Type=${Type}&HsptID=${HsptID}&SubDeptNature=${SubDeptNature}`,
      callbackFun,
      "data",
      getLogout,
      false
    );
  };

  const addObservation = (payload, callbackFun) => {
    newPostApi(
      `Observation/Post`,
      payload,
      callbackFun,
      "msg",
      getLogout,
      true
    );
  };

  return {
    getWardNature,
    getAdmitWards,
    addAdmission,
    getObservationRooms,
    addObservation,
    getAdmitPatientHistory,
    getWardNature,
    getAdmitWards,
    getObservationRooms,
    getUOPatientHistory,
  };
};
