import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  NAV_STYLE_DRAWER,
  NAV_STYLE_FIXED,
  NAV_STYLE_MINI_SIDEBAR,
  NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
  TAB_SIZE,
  THEME_TYPE_LITE,
} from "../../constants/ThemeSetting";
import _w_logo from "../../images/w-logo.png";
import _logo_white from "../../images/logo-white.png";
import _logo from "../../images/logo.png";
import ssa_logo from "../../images/SSA_logo.png";
import eHealthMatrix from "../../images/eHealthMatrix.png";

const SidebarLogo = ({ sidebarCollapsed, setSidebarCollapsed }) => {
  const { width, themeType } = useSelector(({ settings }) => settings);
  let navStyle = useSelector(({ settings }) => settings.navStyle);
  if (width < TAB_SIZE && navStyle === NAV_STYLE_FIXED) {
    navStyle = NAV_STYLE_DRAWER;
  }

  return (
    <div
      className="gx-layout-sider-header"
      style={{ backgroundColor: "#29348e" }}
    >
      {navStyle === NAV_STYLE_FIXED || navStyle === NAV_STYLE_MINI_SIDEBAR ? (
        <div className="gx-linebar">
          <i
            className={`gx-icon-btn icon icon-${
              !sidebarCollapsed ? "menu-unfold" : "menu-fold"
            } ${themeType !== THEME_TYPE_LITE ? "gx-text-white" : ""}`}
            onClick={() => {
              setSidebarCollapsed(!sidebarCollapsed);
            }}
          />
        </div>
      ) : null}

      <Link to="/dashboard" className="gx-site-logo">
        {navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR && width >= TAB_SIZE ? (
          <img alt="lo" src={eHealthMatrix} />
        ) : themeType === THEME_TYPE_LITE ? (
          <img alt="logo1" src={eHealthMatrix} height="15px" />
        ) : (
          <>
            <img alt="logo2" src={eHealthMatrix} height="35px" />
            {/*<div
              style={{
                display: "inline",
                paddingInline: "4px",
                fontSize: "20px",
                letterSpacing: "1px",
                fontWeight: "500",
              }}
            >
              <span style={{ color: "#d27f24" }}>H</span>
              <span style={{ color: "#0689ad" }}>M</span>
              <span style={{ color: "#d27f24" }}>I</span>
              <span style={{ color: "#0689ad" }}>S</span>
            </div>*/}
          </>
        )}
      </Link>
    </div>
  );
};

export default SidebarLogo;
