import {
  SET_SERVICE_RATE_DATA,
  EDIT_SERVICE_RATE_FLAG,
  EDIT_SERVICE_RATE_RECORD,
  SET_SERVICE_RATE_FILTERED_DATA,
  SET_SERVICE_RATE_TYPE,
} from "../../../constants/ActionTypes";

const INIT_STATE = {
  servicesRateData: [],
  editFlag: false,
  editRecord: null,
  filteredServiceRateData: [],
  serviceRateType: "Normal",
};

const ServiceRateSetup = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_SERVICE_RATE_DATA: {
      return {
        ...state,
        servicesRateData: action.payload,
      };
    }

    case EDIT_SERVICE_RATE_FLAG: {
      return {
        ...state,
        editFlag: action.payload,
      };
    }

    case SET_SERVICE_RATE_TYPE: {
      return {
        ...state,
        serviceRateType: action.payload,
      };
    }

    case EDIT_SERVICE_RATE_RECORD: {
      return {
        ...state,
        editRecord: action.payload,
      };
    }

    case SET_SERVICE_RATE_FILTERED_DATA: {
      return {
        ...state,
        filteredServiceRateData: action.payload,
      };
    }

    default:
      return state;
  }
};

export default ServiceRateSetup;
