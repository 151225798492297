import { useState } from "react";
import { httpClient } from "../../Api";
import { useAuth } from "../../../authentication";
import { postApi, putApi, getApi } from "../../Axios";

export const useRole = () => {
  const { getLogout } = useAuth();

  const addRole = (role, callbackFun) => {
    postApi(`Administration/Role`, role, callbackFun, "msg", getLogout, true);
  };

  const getRole = (callbackFun) => {
    getApi(`Administration/Role`, callbackFun, "data", getLogout, false);
  };

  const getRoleById = (roleID, callbackFun) => {
    getApi(
      `Administration/Role/${parseInt(roleID, 10)}`,
      callbackFun,
      "data",
      getLogout,
      true
    );
  };

  const updateRole = (roleID, role, callbackFun) => {
    putApi(
      `Administration/Role/${roleID}`,
      role,
      callbackFun,
      "msg",
      getLogout,
      true
    );
  };

  return {
    addRole,
    getRole,
    getRoleById,
    updateRole,
  };
};
