import React from "react";
import { Button, Checkbox, Form, Input, Row, Space, Tooltip } from "antd";
import { Link, useHistory } from "react-router-dom";
import IntlMessages from "util/IntlMessages";
import { useAuth } from "../authentication";
import AppNotificationContainer from "../components/AppNotificationContainer";
import _logo from "../images/SSA_logo.png";
import _eHealthMatrix from "../images/eHealthMatrixNew.png";
import user_filled_yellow from "../images/user_filled_yellow.png";
import LoginBackground from "../images/Login/LoginBackground.png";
import "./logincss.css"
import { UserOutlined } from "@ant-design/icons";


const SignIn = () => {
  const { isLoading, error, userLogin } = useAuth();
  // const history = useHistory();

  const onFinishFailed = (errorInfo) => {};

  const onFinish = (values) => {
    // history.push("/sample");
    userLogin(values);
  };

  return (
    <div style={{
      backgroundImage: `url(${LoginBackground})`  , height : "100%" , margin: 0 ,
      backgroundPosition: 'center', backgroundSize: 'cover', backgroundRepeat: 'no-repeat'
      }}>
    <div className="gx-app-login-wrap" >
      <div style={{marginLeft: "5%" , width: "60%" , minWidth: "300px" ,maxWidth: "800px"}}>
        <div>
          {/* <div className="gx-app-logo-content">
            <div>
              <img src="https://via.placeholder.com/272x395" alt="Neature" />
            </div>
            <div className="gx-app-logo-wid">
              <h1>
                <IntlMessages id="app.userAuth.signIn" />
              </h1>
              <p>
                <IntlMessages id="app.userAuth.bySigning" />
              </p>
              <p>
                <IntlMessages id="app.userAuth.getAccount" />
              </p>
            </div>
          </div> */}
          <div className="gx-app-login-content">
          <div className="gx-app-logo">
              <img
                alt="example"s
                src={_eHealthMatrix}
                style={{ height: "80px", width: "250px" }}
              />
              <div className="login-heading-class">
                <Row style={{color: "#000000" , marginTop: "20px" }}>
              <IntlMessages id="hms.welcome" />
                </Row>
              <Row style={{color: "#2c3794" , marginBottom: "20px" }}>
              <IntlMessages id="hms.greetings" />
              </Row>
              </div>
            </div>
            <Form
              initialValues={{ remember: true }}
              name="basic"
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              className="gx-signin-form gx-form-row0"
            >

              <Form.Item
                initialValue=""
                rules={[
                  {
                    required: true,
                    message: "The input is not valid username!",
                  },
                ]}
                name="userName"
              >
                <Input
                placeholder="username"
                suffix={
                  <Tooltip title="Enter User Name">
                    <img
                alt="example"s
                src={user_filled_yellow}
                style={{ height: "15px", width: "15px" }}
              />
                  </Tooltip>
                }
                style={{padding : "15px"}}
                />
              </Form.Item>
              <Form.Item
                initialValue=""
                rules={[
                  { required: true, message: "Please input your password!" },
                ]}
                name="password"
              >
                <Input.Password
                 type="password"
                  placeholder="Password"
                  style={{padding : "15px"}} />
              </Form.Item>
              <Form.Item>
                <Row gutter={24} justify="space-between">
                <Link to="/signup">
                  <u>
                  <IntlMessages id="app.userAuth.forget" />
                  </u>
                </Link>
                <Checkbox>
                  <IntlMessages id="appModule.remember" />
                </Checkbox>
                </Row>
                {/* <span className="gx-signup-form-forgot gx-link">
                  <IntlMessages id="appModule.termAndCondition" />
                </span> */}
              </Form.Item>
              <Form.Item>
                <Row gutter={24}
                justify="space-around"
                >
                <Button type="primary" className="gx-mb-0" htmlType="submit" shape="round" >
                <div className="login-button-class">
                  <IntlMessages id="app.userAuth.signIn"/>
                  </div>
                </Button>
                </Row>
                </Form.Item>
                {/* <span>
                  <IntlMessages id="app.userAuth.or" />
                </span>{" "}
                <Link to="/signup">
                  <IntlMessages id="app.userAuth.signUp" />
                </Link>
              <span className="gx-text-light gx-fs-sm">
                {" "}
                demo user name: 'admin' and password: '123'
              </span> */}
            </Form>
          </div>
          <AppNotificationContainer loading={isLoading} error={error} />
        </div>
      </div>
    </div>
    </div>
  );
};

export default SignIn;
