import { httpClient } from "../../Api";
import {
  fetchStart as gFetchStart,
  fetchSuccess as gFetchSuccess,
  fetchError as gFetchError,
  showMessage as gShowMessage,
  hideMessage as gHideMessage,
} from "../../../appRedux/actions";
import { useDispatch } from "react-redux";

export const usePackage = () => {
  const dispatch = useDispatch();

  const fetchStart = () => {
    dispatch(gFetchStart());
  };

  const fetchSuccess = (message) => {
    dispatch(gFetchSuccess());
    dispatch(gShowMessage(message));
  };

  const fetchError = (error) => {
    dispatch(gFetchError(error));
  };

  const addPackage = (packages, callbackFun) => {
    fetchStart();
    httpClient
      .post(`Administration/ManagePackage/SavePackage`, packages)
      .then(({ data }) => {
        fetchSuccess("Added Successfully!");
        if (callbackFun)
          getPackages(packages.sponserID, packages.packageType, callbackFun);
      })
      .catch(function (error) {
        fetchError(error.message);
      });
  };

  const assignServiceToPackage = (model, callbackFun) => {
    fetchStart();
    httpClient
      .post(
        `Administration/ManagePackage/SaveServicesAganistSponsersAndPackages`,
        model
      )
      .then(({ data }) => {
        fetchSuccess(data);
        if (callbackFun) callbackFun(data);
      })
      .catch(function (error) {
        fetchError(error.message);
      });
  };

  const getPackages = (SponserID, PackageType, callbackFun) => {
    fetchStart();
    httpClient
      .get(
        `Administration/ManagePackage/GetPackageDeatil/${SponserID},${PackageType}`
      )
      .then(({ data }) => {
        fetchSuccess("");
        if (data != "No Record Found") if (callbackFun) callbackFun(data);
        if (data == "No Record Found") if (callbackFun) callbackFun(null);
      })
      .catch(function (error) {
        fetchError(error.message);
      });
  };

  const getPackageType = (callbackFun) => {
    fetchStart();
    httpClient
      .get(`Administration/ManagePackage/GetPackageAndServiceType`)
      .then(({ data }) => {
        fetchSuccess("");
        if (data != "No Record Found") if (callbackFun) callbackFun(data);
        if (data == "No Record Found") if (callbackFun) callbackFun(null);
      })
      .catch(function (error) {
        fetchError(error.message);
      });
  };

  // /api/Administration/ManagePackage/UpdatePackage/{PackageID}
  const updatePackage = (model, callbackFun) => {
    fetchStart();
    httpClient
      .put(
        `Administration/ManagePackage/UpdatePackage/${model.packageID}`,
        model
      )
      .then(({ data }) => {
        fetchSuccess("Updated Successfully!");
        if (callbackFun)
          getPackages(model.sponserID, model.packageType, callbackFun);
      })
      .catch(function (error) {
        fetchError(error.message);
      });
  };

  return {
    addPackage,
    getPackages,
    updatePackage,
    getPackageType,
    assignServiceToPackage,
  };
};
