import {SET_PANEL_DATA, EDIT_PANEL_FLAG, EDIT_PANEL_RECORD} from '../../../constants/ActionTypes';

const INIT_STATE = {
    panelsData : [],
    editFlag:false,
    editRecord:null,
};

const Panels = (state = INIT_STATE, action) => {
    switch (action.type) {

      case SET_PANEL_DATA: {
        return {
          ...state,
          panelsData: action.payload,
        }
      };

      case EDIT_PANEL_FLAG: {
        return {
          ...state,
          editFlag: action.payload,
        }
      };

      case EDIT_PANEL_RECORD: {
        return {
          ...state,
          editRecord: action.payload,
        }
      };

      default:
        return state;
    }
  }
  
  export default Panels;
  