import React, { createContext, useContext, useState, useEffect } from 'react';

// Create a context
const ScreenWidthContext = createContext();

// Create a provider component
export const ScreenWidthProvider = ({ children }) => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    const handleResize = () => {
        setWindowWidth(window.innerWidth);
    };

    useEffect(() => {
        // Set initial window width
        setWindowWidth(window.innerWidth);

        // Add event listener for window resize
        window.addEventListener('resize', handleResize);

        // Remove event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []); // Empty dependency array ensures that the effect runs only once on mount

    return (
        <ScreenWidthContext.Provider value={windowWidth}>
            {children}
        </ScreenWidthContext.Provider>
    );
};

// Create a custom hook to use the context value
export const useScreenWidth = () => {
    return useContext(ScreenWidthContext);
};