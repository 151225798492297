import React from "react";
import { Menu } from "antd";
import { Link } from "react-router-dom";
import CustomScrollbars from "../../util/CustomScrollbars";
import SidebarLogo from "./SidebarLogo";
import { THEME_TYPE_LITE } from "../../constants/ThemeSetting";
import { useSelector, useDispatch } from "react-redux";
import "./menu.css";
import {
  convertToMenuStructure,
  getMenuIcon,
  toCamelCase,
} from "../../util/utility-methods";
import { FunnelPlotOutlined } from "@ant-design/icons";
import IntlMessages from "../../util/IntlMessages";
import {
  setSelectedTemplateID,
  setSelectedMainGroupID,
} from "../../appRedux/actions";

const SidebarContent = ({ sidebarCollapsed, setSidebarCollapsed, auth }) => {
  const { themeType } = useSelector(({ settings }) => settings);
  const dispatch = useDispatch();
  const pathname = useSelector(({ common }) => common.pathname);
  const templatesNav = useSelector(
    ({ templateRetrieval }) => templateRetrieval.assignedTemplates
  );
  const mainGroupNav = useSelector(
    ({ templateRetrieval }) => templateRetrieval.assignedMainGroup
  );
  const user = auth?.User;
  const menuInfo = user.MenuInfo;
  const { SubMenu } = Menu;

  const selectedKeys = pathname.substr(1);
  const defaultOpenKeys = selectedKeys.split("/")[1];

  const menuArray = convertToMenuStructure(menuInfo);
  const hasTemplateRetrieval = menuInfo.some(
    (menu) => menu.PName === "Template Retrieval"
  );

  return (
    <>
      <SidebarLogo
        sidebarCollapsed={sidebarCollapsed}
        setSidebarCollapsed={setSidebarCollapsed}
      />
      <div className="gx-sidebar-content">
        <CustomScrollbars className="gx-layout-sider-scrollbar">
          <Menu
            defaultOpenKeys={[defaultOpenKeys]}
            selectedKeys={[selectedKeys]}
            theme={themeType === THEME_TYPE_LITE ? "lite" : "dark"}
            mode="inline"
            style={{ marginBottom: "20px" }}
          >
            {menuArray &&
              menuArray.length &&
              menuArray.map((menu) => {
                if (menu.Mname === "Templates") return <></>;
                return (
                  <SubMenu
                    key={toCamelCase(menu.Mname)}
                    icon={getMenuIcon(menu.MIcon)}
                    title={menu.Mname}
                  >
                    {menu?.Pages &&
                      menu?.Pages.length &&
                      menu.Pages.map((page) =>
                        page.PageId ? (
                          <Menu.Item key={toCamelCase(page.PName)}>
                            <Link to={page.PageURL}>
                              <span>{page.PName}</span>
                            </Link>
                          </Menu.Item>
                        ) : (
                          <SubMenu
                            key={toCamelCase(page.Mname)}
                            icon={getMenuIcon(page.MIcon)}
                            title={page.Mname}
                            className="nested-sub-menu"
                          >
                            {page?.Pages &&
                              page?.Pages.length &&
                              page.Pages.map((subPage) => (
                                <Menu.Item key={toCamelCase(subPage.PName)}>
                                  <Link to={subPage.PageURL}>
                                    <span>{subPage.PName}</span>
                                  </Link>
                                </Menu.Item>
                              ))}
                          </SubMenu>
                        )
                      )}
                  </SubMenu>
                );
              })}
            {hasTemplateRetrieval && (
              <SubMenu
                key="template-sidebar"
                icon={<FunnelPlotOutlined />}
                title={<IntlMessages id="sidebar.templates" />}
              >
                {templatesNav.map((templatesNavData) => {
                  if (templatesNav.length > 0) {
                    return (
                      <SubMenu
                        key={"TempId=" + templatesNavData.EmrTemplateID}
                        title={templatesNavData.TemplateName}
                      >
                        {mainGroupNav.map((outerData) => {
                          if (outerData.length > 0) {
                            if (
                              outerData[0].TemplateID ===
                              templatesNavData.EmrTemplateID
                            ) {
                              return outerData.map((innerData) => {
                                return (
                                  <Menu.Item
                                    key={"MainGroupId=" + innerData.MaingroupID}
                                  >
                                    <Link
                                      to="TemplateRetrieval"
                                      onClick={() => {
                                        dispatch(
                                          setSelectedTemplateID(
                                            templatesNavData.EmrTemplateID
                                          )
                                        );
                                        dispatch(
                                          setSelectedMainGroupID(
                                            innerData.MaingroupID
                                          )
                                        );
                                      }}
                                    >
                                      <span>{innerData.MaingroupName}</span>
                                    </Link>
                                  </Menu.Item>
                                );
                              });
                            }
                          }
                        })}
                      </SubMenu>
                    );
                  }
                })}
              </SubMenu>
            )}
          </Menu>
          {!sidebarCollapsed && (
            <p
              style={{
                position: "sticky",
                bottom: "5px",
                left: "12px",
                top: "95%",
                paddingLeft: "24px",
                backgroundColor: "#29348e",
                color: "#fafafa",
                fontSize: "12px",
              }}
            >
              © 2024 All rights reserved.
            </p>
          )}
        </CustomScrollbars>
      </div>
    </>
  );
};

export default React.memo(SidebarContent);
