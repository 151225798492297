import {
  ADDED_FAV_PROCEDURE,
  REMOVE_FAV_PROCEDURE,
} from "../../constants/ActionTypes";

const INIT_STATE = {
  addedFavProc: [],
};

const FavProcedure = (state = INIT_STATE, action) => {
  switch (action.type) {
    case ADDED_FAV_PROCEDURE: {
      return {
        ...state,
        addedFavProc: action.payload,
      };
    }
    case REMOVE_FAV_PROCEDURE: {
      var { favProcedure } = state;
      var filteredResults;
      filteredResults = favProcedure.filter(
        (record) => record.sr !== action.payload
      );

      return {
        ...state,
        favProcedure: filteredResults,
      };
    }
    default:
      return state;
  }
};

export default FavProcedure;
