import React from "react";
import _loader from "../../images/loader.svg";
import small from "../../images/small.png";

const CircularProgress = ({ className }) => (
  <div className={`loader ${className}`}>
    <img src={_loader} alt="loader" />
  </div>
);
export default CircularProgress;
