import { SET_MENU_RECORD, EDIT_MENU_FLAG, EDIT_MENU_RECORD, SET_MENU_PAGE_RECORD, EDIT_PAGE_FLAG, EDIT_PAGE_RECORD, SET_SELECTED_PAGES } from '../../constants/ActionTypes';

export function setMenuData(values) {
    return (dispatch) => {
      dispatch({
        type: SET_MENU_RECORD,
        payload: values
      });
    }
}

export function EditMenuFlag(values) {
  return (dispatch) => {
    dispatch({
      type: EDIT_MENU_FLAG,
      payload: values
    });
  }
}

export function EditMenuRecord(values) {
  return (dispatch) => {
    dispatch({
      type: EDIT_MENU_RECORD,
      payload: values
    });
  }
}

export function setMenuPageData(values) {
  return (dispatch) => {
    dispatch({
      type: SET_MENU_PAGE_RECORD,
      payload: values
    });
  }
}

export function EditPageFlag(values) {
  return (dispatch) => {
    dispatch({
      type: EDIT_PAGE_FLAG,
      payload: values
    });
  }
}

export function EditPageRecord(values) {
  return (dispatch) => {
    dispatch({
      type: EDIT_PAGE_RECORD,
      payload: values
    });
  }
}

export function setSelectedPages(values) {
  return (dispatch) => {
    dispatch({
      type: SET_SELECTED_PAGES,
      payload: values
    });
  }
}