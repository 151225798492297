import {SET_SERVICES_DATA, EDIT_SERVICE_FLAG, EDIT_SERVICE_RECORD} from '../../../constants/ActionTypes';


export function setServicesData(values) {
    return (dispatch) => {
      dispatch({
        type: SET_SERVICES_DATA,
        payload: values
      });
    }
}

export function EditServiceFlag(values) {
  return (dispatch) => {
    dispatch({
      type: EDIT_SERVICE_FLAG,
      payload: values
    });
  }
}

export function EditServiceRecord(values) {
  return (dispatch) => {
    dispatch({
      type: EDIT_SERVICE_RECORD,
      payload: values
    });
  }
}

