import {
    SET_LOGIN,
  } from "../../constants/ActionTypes";
  
  const INIT_STATE = {
    setLoginData: [],
  };
  
  const Login = (state = INIT_STATE, action) => {
    switch (action.type) {
      case SET_LOGIN: {
        return {
          ...state,
          setLoginData: action.payload,
        };
      }
      default:
        return state;
    }
  };
  
  export default Login;