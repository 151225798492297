import {
    SET_SHIFTS,
    EDIT_SHIFTS,
  } from "../../constants/ActionTypes";
  
  const INIT_STATE = {
    setShiftsData: [],
    editShiftsData:[],
  };
  
  const Shifts = (state = INIT_STATE, action) => {
    switch (action.type) {
      case SET_SHIFTS: {
        return {
          ...state,
          setShiftsData: action.payload,
        };
      }
      case EDIT_SHIFTS: {
        return {
          ...state,
          editShiftsData: action.payload,
        };
      }
      default:
        return state;
    }
  };
  
  export default Shifts;