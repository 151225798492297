import urPK from 'antd/lib/locale-provider/ur_PK';
import urMessages from "../locales/ur_PK.json";

const UrLan = {
  messages: {
    ...urMessages
  },
  antd: urPK,
  locale: 'ur_PK',
};
export default UrLan;