import { SET_DEPARTMENT_DATA, EDIT_DEPARTMENT_FLAG, EDIT_DEPARTMENT_RECORD, SAVE_EDITED_DEPARTMENT_RECORD ,LOGOUT } from '../../constants/ActionTypes';

const INIT_STATE = {
    departmentsRecord: [],
    editFlag:false,
    editRecord:null,
};


const AddDepartment = (state = INIT_STATE, action) => {
    switch (action.type) {
      case SET_DEPARTMENT_DATA: {
        return {
          ...state,
          departmentsRecord: action.payload,
        }
      };
      case EDIT_DEPARTMENT_FLAG: {
        return {
          ...state,
          editFlag: action.payload,
        }
      };
      case EDIT_DEPARTMENT_RECORD: {
        return {
          ...state,
          editRecord: action.payload,
        }
      };
      case SAVE_EDITED_DEPARTMENT_RECORD: {
        let index = state.addForm.findIndex( x => x.sr === action.payload.sr );
        let temp = action.payload;
        action.payload = [ ...state.addForm ];
        action.payload[index] = temp;
        return {
          ...state,
          addForm: action.payload,
        }
      };
      case LOGOUT: 
        return INIT_STATE;
      default:
        return state;
    }
  }


  
  export default AddDepartment;
  