import { useState } from "react";
import { httpClient } from "../Api";
import { message } from "antd";
import { useAuth } from "../../authentication";
import { postApi, putApi, getApi, deleteApi } from "../Axios";
// import { deleteApi } from "../Axios_Dev";

export const useMenuPage = () => {
  const { getLogout } = useAuth();

  const addMainMenu = (menu, callbackFun) => {
    postApi(`Administration/Menu`, menu, callbackFun, "msg", getLogout, true);
  };

  const getMainMenu = (callbackFun) => {
    getApi(`Administration/Menu`, callbackFun, "data", getLogout, false);
  };

  const getMainMenuById = (id, callbackFun) => {
    getApi(`Administration/Menu/${id}`, callbackFun, "data", getLogout, true);
  };

  const updateMainMenu = (menu, id, callbackFun) => {
    putApi(
      `Administration/Menu/${id}`,
      menu,
      callbackFun,
      "msg",
      getLogout,
      true
    );
  };

  const addMenuPage = (menuPage, callbackFun) => {
    postApi(
      `Administration/MenuPages`,
      menuPage,
      callbackFun,
      "msg",
      getLogout,
      true
    );
  };

  const updateMenuPage = (menuPage, callbackFun) => {
    putApi(
      `Administration/MenuPages/${menuPage.menuPgID}`,
      menuPage,
      callbackFun,
      "msg",
      getLogout,
      true
    );
  };

  const getMenuPagesByMenuId = (menuId, callbackFun) => {
    getApi(
      `Administration/MenuPages/${menuId}`,
      callbackFun,
      "data",
      getLogout,
      true
    );
  };

  const getMenuIcons = (callbackFun) => {
    getApi(
      `Administration/Menu/GetMenuIcon`,
      callbackFun,
      "data",
      getLogout,
      false
    );
  };

  const deletePageSetup = (MenuPgId, callbackFun) => {
    deleteApi(
      `Administration/MenuPages/${MenuPgId}`,
      callbackFun,
      "msg",
      getLogout,
      true
    );
  };

  return {
    addMainMenu,
    getMainMenuById,
    updateMainMenu,
    getMainMenu,
    addMenuPage,
    getMenuPagesByMenuId,
    updateMenuPage,
    getMenuIcons,
    deletePageSetup,
  };
};
