// import "./styles/formStyles.css";
import React from "react";
import { Provider } from "react-redux";
import { ConnectedRouter } from "connected-react-router";
import { Route, Switch } from "react-router-dom";
import "./assets/vendors/style";
import "./styles/wieldy.less";
import { PersistGate } from "redux-persist/integration/react";

import configureStore, { history } from "./appRedux/store";
import App from "./containers/App/index";
import { AuthProvider } from "./authentication";
import { ScreenWidthProvider } from "./context/ScreenWidthContext";

export const { store, persistor } = configureStore();

const NextApp = () => (
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <ConnectedRouter history={history}>
        <AuthProvider>
          <ScreenWidthProvider>
            <Switch>
              <Route path="/" component={App} />
            </Switch>
          </ScreenWidthProvider>
        </AuthProvider>
      </ConnectedRouter>
    </PersistGate>
  </Provider>
);

export default NextApp;
