import {
    ADD_OBSERVATION,
    SET_OBSERVATION_ROOMS,
    OBSERVATION_ACCESSED,
    OBSERVATION_ACCESSED_DATA,
    OBSERVATION_ACCESSED_DOCTOR
  } from "../../constants/ActionTypes";
  
  const INIT_STATE = {
    setObservationData: [],
    setObservationRoomsData: [],
    setObservationAccessed: false,
    setObservationAccessedData: {},
    observationAccessedDoctor: false,
  };
  
  const Observation = (state = INIT_STATE, action) => {
    switch (action.type) {
      case ADD_OBSERVATION: {
        return {
          ...state,
          setObservationData: action.payload,
        };
      }
      case SET_OBSERVATION_ROOMS: {
        return {
          ...state,
          setObservationRoomsData: action.payload,
        };
      }
      case OBSERVATION_ACCESSED: {
        return {
          ...state,
          setObservationAccessed: action.payload,
        };
      }

      case OBSERVATION_ACCESSED_DATA: {
        return {
          ...state,
          setObservationAccessedData: action.payload,
        };
      }
      case OBSERVATION_ACCESSED_DOCTOR: {
        return {
          ...state,
          observationAccessedDoctor: action.payload,
        };
      }
      // case SET_OBSERVATION_ROOMS: {
      //   return INIT_STATE;
      // }
      default:
        return state;
    }
  };
  
  export default Observation;