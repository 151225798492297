import { ASSIGN_TEMPLATE, REMOVE_TEMPLATE } from "../../constants/ActionTypes";

const INIT_STATE = {
  assignedTemplates: [],
};

const TemplateAssignment = (state = INIT_STATE, action) => {
  switch (action.type) {
    case ASSIGN_TEMPLATE: {
      return {
        ...state,
        assignedTemplates: [...state.assignedTemplates, ...action.payload],
      };
    }
    case REMOVE_TEMPLATE: {
      var { assignedTemplates } = state;
      var filteredResults;
      filteredResults = assignedTemplates.filter(
        (record) => record.id !== action.payload
      );

      return {
        ...state,
        assignedTemplates: filteredResults,
      };
    }
    default:
      return state;
  }
};

export default TemplateAssignment;
