import { httpClient } from "../../Api";
import { useAuth } from "../../../authentication";
import { postApi, getApi } from "../../Axios";

export const useServiceMainGroup = () => {
  const { getLogout } = useAuth();

  const addServiceMainGroup = (serviceMainGroup, callbackFun) => {
    postApi(
      `Administration/ServicesMainGroups`,
      serviceMainGroup,
      callbackFun,
      "msg",
      getLogout,
      true
    );
  };

  const getServiceMainGroup = (callbackFun) => {
    getApi(
      `Administration/ServicesMainGroups`,
      callbackFun,
      "data",
      getLogout,
      false
    );
  };

  return {
    addServiceMainGroup,
    getServiceMainGroup,
  };
};
