import {
  ADDED_TEMPLATES,
  EDIT_TEMPLATE,
  ADDED_MG_FOR_TEMPLATE,
  EDIT_MAIN_GROUP,
  CLEAR_EDIT_MAIN_GROUP,
  ADDED_MH_FOR_TEMP_AND_MG,
  EDIT_MAIN_HEADING,
  ADDED_HEADINGS,
  EDIT_HEADING,
  ADDED_ELEMENTS,
  EDIT_ELEMENT,
  TEMPLATE_TABS,
} from "../../constants/ActionTypes";

export function AddedTemplates(values) {
  return (dispatch) => dispatch({ type: ADDED_TEMPLATES, payload: values });
}

export function SetTemplateTabs(values) {
  return (dispatch) => dispatch({ type: TEMPLATE_TABS, payload: values });
}

export function EditTemplate(values) {
  return (dispatch) => dispatch({ type: EDIT_TEMPLATE, payload: values });
}

export function AddedMGForTemplate(values) {
  return (dispatch) =>
    dispatch({ type: ADDED_MG_FOR_TEMPLATE, payload: values });
}

export function EditMainGroup(values) {
  return (dispatch) => dispatch({ type: EDIT_MAIN_GROUP, payload: values });
}

export function ClearEditMainGroup() {
  return (dispatch) => dispatch({ type: CLEAR_EDIT_MAIN_GROUP });
}

export function EditMainHeading(values) {
  return (dispatch) => dispatch({ type: EDIT_MAIN_HEADING, payload: values });
}

export function AddedMHForTempAndMG(values) {
  return (dispatch) =>
    dispatch({ type: ADDED_MH_FOR_TEMP_AND_MG, payload: values });
}

export function AddedHeadings(values) {
  return (dispatch) => dispatch({ type: ADDED_HEADINGS, payload: values });
}

export function EditHeading(values) {
  return (dispatch) => dispatch({ type: EDIT_HEADING, payload: values });
}

export function AddedElements(values) {
  return (dispatch) => dispatch({ type: ADDED_ELEMENTS, payload: values });
}

export function EditElement(values) {
  return (dispatch) => dispatch({ type: EDIT_ELEMENT, payload: values });
}
