import { SET_SERVICE_SUB_GROUP_DATA, EDIT_SERVICE_SUB_GROUP_RECORD, EDIT_SERVICE_SUB_GROUP_FLAG} from '../../../constants/ActionTypes';

const INIT_STATE = {
    serviceSubGroup : [],
    editFlag:false,
    editRecord:null,
};

const AddServiceSubGroup = (state = INIT_STATE, action) => {
    switch (action.type) {

      case SET_SERVICE_SUB_GROUP_DATA: {
        return {
          ...state,
          serviceSubGroup: action.payload,
        }
      };

      case EDIT_SERVICE_SUB_GROUP_FLAG: {
        return {
          ...state,
          editFlag: action.payload,
        }
      };

      case EDIT_SERVICE_SUB_GROUP_RECORD: {
        return {
          ...state,
          editRecord: action.payload,
        }
      };

      default:
        return state;
    }
  }
  
  export default AddServiceSubGroup;
  