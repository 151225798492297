import {
    BILLING_ACCESSED,
    BILLING_ACCESSED_DATA,
    BILLING_PATIENTS_DATA,
    BILLING_PATIENT_INVOICE_DATA
  } from "../../constants/ActionTypes";
  



  export function setBillingAccessed(values) {
    return (dispatch) => dispatch({ type: BILLING_ACCESSED, payload: values });
  }

  export function setBillingAccessedData(values) {
    return (dispatch) => dispatch({ type: BILLING_ACCESSED_DATA, payload: values });
  }

  export function setBillingPatientsData(values) {
    return (dispatch) => dispatch({ type: BILLING_PATIENTS_DATA, payload: values });
  }

  export function setBillingPatientInvoiceData(values) {
    return (dispatch) => dispatch({ type: BILLING_PATIENT_INVOICE_DATA, payload: values });
  }