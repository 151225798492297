import {SET_SERVICE_LATERALITY_DATA, FETCH_ERROR, FETCH_START, FETCH_SUCCESS, HIDE_MESSAGE,
   SHOW_MESSAGE, AUTHENTICATED_USER, SET_COUNTRIES_DATA, SET_CITIES_DATA, SET_RATE_TYPES_DATA} from "../../constants/ActionTypes";

export const fetchStart = () => {
  return {
    type: FETCH_START
  }
};

export const fetchSuccess = () => {
  return {
    type: FETCH_SUCCESS
  }
};

export const fetchError = (error) => {
  return {
    type: FETCH_ERROR,
    payload: error
  }
};

export const showMessage = (message) => {
  return {
    type: SHOW_MESSAGE,
    payload: message
  }
};

export const hideMessage = () => {
  return {
    type: HIDE_MESSAGE
  }
};

export function setAuthenticUser(user) {
  return (dispatch) => {
    dispatch({
      type: AUTHENTICATED_USER,
      payload: user
    });
  }
}

export function setCountriesData(countries) {
  return (dispatch) => {
    dispatch({
      type: SET_COUNTRIES_DATA,
      payload: countries
    });
  }
}

export function setCitiesData(cities) {
  return (dispatch) => {
    dispatch({
      type: SET_CITIES_DATA,
      payload: cities
    });
  }
}

export function setLateralityData(lateralities) {
  return (dispatch) => {
    dispatch({
      type: SET_SERVICE_LATERALITY_DATA,
      payload: lateralities
    });
  }
}

export function setRateTypesData(types) {
  return (dispatch) => {
    dispatch({
      type: SET_RATE_TYPES_DATA,
      payload: types
    });
  }
}





