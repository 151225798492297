import {
    SET_LAB_VISIT_HISTORY,
    SET_RAD_VISIT_HISTORY
  } from "../../constants/ActionTypes";
  


  export function setInvsetLabVisitHistoryData(values) {
    return (dispatch) => dispatch({ type: SET_LAB_VISIT_HISTORY, payload: values });
  }

  export function setInvsetRadVisitHistoryData(values) {
    return (dispatch) => dispatch({ type: SET_RAD_VISIT_HISTORY, payload: values });
  }