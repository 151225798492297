import {
  SET_WAITING_PATIENT,
  SET_ATTENDED_PATIENT,
  SET_REFERRAL_PATIENT,
  SET_ADMITTED_PATIENT,
  SET_OBSERVATION_PATIENT,
  SET_CURRENT_PATIENT_TYPE,
  SET_TOTAL_WAITING_PATIENT,
  SET_TOTAL_ATTENDED_PATIENT,
  SET_TOTAL_REFERRAL_PATIENT,
  SET_TOTAL_ADMITTED_PATIENT,
  SET_TOTAL_OBSERVATION_PATIENT,
  SET_PATIENT_MR_AND_VISITID,
} from "../../constants/ActionTypes";

const INIT_STATE = {
  setWaitingPatient: [],
  setAttendedPatient: [],
  setReferralPatient: [],
  setAdmittedPatient: [],
  setObservationPatient: [],
  setCurrentPatientType: 1,
  setTotalWaitingPatient: {
    TWP : 0,
    TAP : 0,
    TRP : 0,
    TOP : 0,
    TAMP: 0,
    WPL : [],
    APL : [],
    RPL : [],
    OPL : [],
    AMPL: [],
  },
  setTotalAttendedPatient: 0,
  setTotalReferralPatient: 0,
  setTotalAdmittedPatient: 0,
  setTotalObservationPatient: 0,
  setPatientDetails: {
    MrNumber : undefined,
    VisitID : undefined
  },
  dataLoaded: false,
};

const DrDashboard = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_WAITING_PATIENT: {
      return {
        ...state,
        setWaitingPatient: action.payload,
      };
    }
    case SET_ATTENDED_PATIENT: {
      return {
        ...state,
        setAttendedPatient: action.payload,
      };
    }
    case SET_REFERRAL_PATIENT: {
      return {
        ...state,
        setReferralPatient: action.payload,
      };
    }
    case SET_ADMITTED_PATIENT: {
      return {
        ...state,
        setAdmittedPatient: action.payload,
      };
    }
    case SET_OBSERVATION_PATIENT: {
      return {
        ...state,
        setObservationPatient: action.payload,
      };
    }
    case SET_TOTAL_WAITING_PATIENT: {
      return {
        ...state,
        setTotalWaitingPatient: action.payload,
      };
    }
    case SET_TOTAL_ATTENDED_PATIENT: {
      return {
        ...state,
        setTotalAttendedPatient: action.payload,
      };
    }
    case SET_TOTAL_REFERRAL_PATIENT: {
      return {
        ...state,
        setTotalReferralPatient: action.payload,
      };
    }
    case SET_TOTAL_ADMITTED_PATIENT: {
      return {
        ...state,
        setTotalAdmittedPatient: action.payload,
      };
    }
    case SET_TOTAL_OBSERVATION_PATIENT: {
      return {
        ...state,
        setTotalObservationPatient: action.payload,
      };
    }
    case SET_CURRENT_PATIENT_TYPE: {
      return {
        ...state,
        setCurrentPatientType: action.payload,
      };
    }
    case SET_PATIENT_MR_AND_VISITID: {
      return {
        ...state,
        setPatientDetails: action.payload,
        dataLoaded: true,
      };
    }
    default:
      return state;
  }
};

export default DrDashboard;
