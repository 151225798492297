export const contactInput = (name, form, e) => {
    let reg = /^[0-9]*$/i;
    let isValid = reg.test(e.currentTarget.value);
    if(isValid){
        console.log("valid**");
        form.setFieldsValue({
            [name]: e.currentTarget.value
        });
    }
    if(!isValid){
        console.log("inValid**");
        form.setFieldsValue({
            [name]: e.currentTarget.value.slice(0, e.currentTarget.value.length - 1)
        })
    }
}