import {
    SET_PATIENT_TOKEN,
    SET_PATIENT_VISIT,
  } from "../../constants/ActionTypes";
  
  const INIT_STATE = {
    setPaitientToken: [],
    setPatientVisit: [],
  };
  
  const PatientVisitToken = (state = INIT_STATE, action) => {
    switch (action.type) {
      case SET_PATIENT_TOKEN: {
        return {
          ...state,
          setPaitientToken: action.payload,
        };
      }
      case SET_PATIENT_VISIT: {
        return {
          ...state,
          setPatientVisit: action.payload,
        };
      }
      default:
        return state;
    }
  };
  
  export default PatientVisitToken;