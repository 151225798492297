import {
  ADDED_FAV_MEDICATION,
  REMOVE_FAV_MEDICATION,
} from "../../constants/ActionTypes";

export function AddedFavMed(values) {
  return (dispatch) =>
    dispatch({ type: ADDED_FAV_MEDICATION, payload: values });
}

export function RemoveFavMedication(value) {
  return (dispatch) =>
    dispatch({ type: REMOVE_FAV_MEDICATION, payload: value });
}
