import {
    ADD_ADMISSION,
    SET_WARDS,
  } from "../../constants/ActionTypes";
  
  const INIT_STATE = {
    addAdmission: [],
    setWardsData: [],
  };
  
  const Admission = (state = INIT_STATE, action) => {
    switch (action.type) {
      case ADD_ADMISSION: {
        return {
          ...state,
          addAdmission: action.payload,
        };
      }
      case SET_WARDS: {
        return {
          ...state,
          setWardsData: action.payload,
        };
      }
      default:
        return state;
    }
  };
  
  export default Admission;